import { Grid, Typography } from '@silverstein-properties/inspirelabs-ui';

interface MessageSuccessProps {
  message: string;
}

export const MessageSuccess = ({ message }: MessageSuccessProps) => {
  return (
    <Grid container flexDirection="column" alignItems="center" spacing={1}>
      <Grid item>
        <img src="./images/success.svg" alt="checkmark" height="80px" />
      </Grid>
      <Grid item>
        <Typography align="center">{message}</Typography>
      </Grid>
    </Grid>
  );
};
