import { useFlags } from 'launchdarkly-react-client-sdk';

export type UseFeatureFlagsReturnType = {
  isMerchantSchedulingEnabled: boolean;
  isMerchantRefundingEnabled: boolean;
  isCancelExperienceEventEnabled: boolean;
  isMessageGuestsEnabled: boolean;
  isCommunicationInboxEnabled: boolean;
  isPublishedListingEnabled: boolean;
  isEditButtonInExperienceDetailsPageEnabled: boolean;
  isEditButtonInMembershipDetailsPageEnabled: boolean;
  isEditLocationEnabled: boolean;
  isMerchantStatementsEnabled: boolean;
  isStatementEventDetailsEnabled: boolean;
  isStatementExportToPdfEnabled: boolean;
  areStatementDashboardCardsEnabled: boolean;
  isMerchantSchedulingListViewEnabled: boolean;
  areMerchantMembershipsEnabled: boolean;
  isParticipantsExportToCsvEnabled: boolean;
  is2FAEnabled: boolean;
  isMerchantTeamEnabled: boolean;
  isViewUserAndBusinessProfileEnabled: boolean;
  isDuplicateMembershipEnabled: boolean;
  isContactPrimaryMemberEnabled: boolean;
  isCancelPrimaryMemberEnabled: boolean;
  isEditProductCapacityEnabled: boolean;
  isRecurringEventsEnabled: boolean;
  isNewMemberSectionEnabled: boolean;
  isCloseSignUpsEnabled: boolean;
  skipImageValidation: boolean;
  isBookingRequestEnabled: boolean;
  isCateringEnabled: boolean;
  isAdvertEnabled: boolean;
  isMediaServiceEnabled: boolean;
  isBookingRequestWithMembershipEnabled: boolean;
  isTerminateMembershipEnabled: boolean;
  isDeleteMembershipEnabled: boolean;
};

export const useFeatureFlags = (): UseFeatureFlagsReturnType => {
  const {
    merchantScheduling,
    merchantRefunding,
    cancelExperienceEvent,
    messageGuests,
    communicationInbox,
    editPublishedListing,
    editButtonInExperienceDetailsPage,
    editLocation,
    merchantStatements,
    statementEventDetails,
    statementExportToPdf,
    statementDashboardCards,
    merchantSchedulingListView,
    merchantMemberships,
    participantsExportToCsv,
    editButtonInMembershipDetailsPage,
    enable2Fa,
    merchantTeams,
    viewUserAndBusinessProfile,
    duplicateMembership,
    contactPrimaryMember,
    cancelPrimaryMember,
    editProductCapacity,
    recurringEvents,
    membersSection,
    closeSignUps,
    skipImageValidation,
    bookingRequests,
    catering,
    advert,
    mediaServiceMerchantImages,
    bookingRequestWithMembership,
    terminateMembership,
    deleteMembership,
  } = useFlags();

  return {
    isMerchantSchedulingEnabled: merchantScheduling,
    isMerchantRefundingEnabled: merchantRefunding,
    isCancelExperienceEventEnabled: cancelExperienceEvent,
    isMessageGuestsEnabled: messageGuests,
    isCommunicationInboxEnabled: communicationInbox,
    isPublishedListingEnabled: editPublishedListing,
    isEditButtonInExperienceDetailsPageEnabled:
      editButtonInExperienceDetailsPage,
    isEditLocationEnabled: editLocation,
    isMerchantStatementsEnabled: merchantStatements,
    isStatementEventDetailsEnabled: statementEventDetails,
    isStatementExportToPdfEnabled: statementExportToPdf,
    areStatementDashboardCardsEnabled: statementDashboardCards,
    isMerchantSchedulingListViewEnabled: merchantSchedulingListView,
    areMerchantMembershipsEnabled: merchantMemberships,
    isParticipantsExportToCsvEnabled: participantsExportToCsv,
    isEditButtonInMembershipDetailsPageEnabled:
      editButtonInMembershipDetailsPage,
    is2FAEnabled: enable2Fa,
    isMerchantTeamEnabled: merchantTeams,
    isViewUserAndBusinessProfileEnabled: viewUserAndBusinessProfile,
    isDuplicateMembershipEnabled: duplicateMembership,
    isContactPrimaryMemberEnabled: contactPrimaryMember,
    isCancelPrimaryMemberEnabled: cancelPrimaryMember,
    isEditProductCapacityEnabled: editProductCapacity,
    isRecurringEventsEnabled: recurringEvents,
    isNewMemberSectionEnabled: membersSection,
    isCloseSignUpsEnabled: closeSignUps,
    skipImageValidation: skipImageValidation,
    isBookingRequestEnabled: bookingRequests,
    isCateringEnabled: catering,
    isAdvertEnabled: advert,
    isMediaServiceEnabled: mediaServiceMerchantImages,
    isBookingRequestWithMembershipEnabled: bookingRequestWithMembership,
    isTerminateMembershipEnabled: terminateMembership,
    isDeleteMembershipEnabled: deleteMembership,
  };
};
