import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAssociatedMerchants, useSwitchAccount } from '@/hooks';
import { Skeleton, TextField } from '@mui/material';
import {
  Autocomplete,
  Container,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { LoadingButton } from '@mui/lab';

interface MerchantDropdownOption {
  label: string;
  value: number;
}

// NOTE: No need to check `isSuperAdmin` here, as this is already done in App routes & menu links
export const AccountSwitchingModule = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { data: merchants, isLoading: isMerchantsLoading } =
    useAssociatedMerchants();

  const [selectedMerchant, setSelectedMerchant] =
    useState<MerchantDropdownOption | null>(null);

  // Set the 'current' merchant as the default selected merchant
  useEffect(() => {
    if (merchants && merchants.length > 0) {
      const defaultMerchant = merchants.find(
        merchant => merchant.isDefaultMerchant
      );
      if (defaultMerchant) {
        setSelectedMerchant({
          label: `${defaultMerchant.name} - (current)`,
          value: defaultMerchant.id,
        });
      }
    }
  }, [merchants]);

  // After user switches account:
  const onSuccess = () => {
    navigate('/dashboard');
  };
  const onError = (error: Error) => {
    console.error('Error switching account:', error);
  };

  const { mutateAsync: switchAccount, isLoading: isSwitchingAccount } =
    useSwitchAccount(onSuccess, onError);

  const handleSwitchAccount = async () => {
    if (selectedMerchant) {
      await switchAccount(selectedMerchant.value);
    }
  };

  const merchantOptions: MerchantDropdownOption[] =
    merchants?.map(merchant => ({
      label: `${merchant.name} ${
        merchant.isDefaultMerchant ? ' - (current)' : ''
      }`,
      value: merchant.id,
    })) || [];

  return (
    <Container
      sx={{
        paddingY: theme.spacers.xxl4,
      }}
      maxWidth="sm"
    >
      <Typography variant="h4" marginBottom={theme.spacers.s}>
        My accounts
      </Typography>
      <Typography variant="body1" marginBottom={theme.spacers.xxl}>
        Which merchant account would you like to switch to?
      </Typography>

      {isMerchantsLoading ? (
        <Skeleton variant="rectangular" height={56} />
      ) : (
        <Autocomplete
          options={merchantOptions}
          renderInput={params => (
            <TextField {...params} label="Select a merchant" />
          )}
          value={selectedMerchant}
          onChange={(_, newValue) =>
            setSelectedMerchant(newValue as MerchantDropdownOption | null)
          }
          isOptionEqualToValue={(option, value) => option.value === value.value}
        />
      )}

      <LoadingButton
        loading={isSwitchingAccount}
        variant="contained"
        onClick={handleSwitchAccount}
        disabled={!selectedMerchant || isSwitchingAccount}
        sx={{ marginTop: theme.spacers.xxl }}
      >
        Switch Account
      </LoadingButton>
    </Container>
  );
};
