import { useAuth } from '../useAuth';
import { useQuery } from '@tanstack/react-query';
import { ApiClient } from '../../api/apiClient';
import { DropdownType } from '@/types';

export const useSubCategoriesChoices = (categoryIds?: number[]) => {
  const { data: user } = useAuth();
  const apiClient = ApiClient();
  const merchantId = user?.primaryMerchantId.toString();

  // Need to format array of categoryIds as a comma-separated string
  // API will look like: /product-lookup-items/service-subcategories?categoryIds=1,2,3
  const formattedCategoryIds = categoryIds?.join(',');

  return useQuery<DropdownType[] | undefined>(
    ['subcategoriesChoices', merchantId, formattedCategoryIds],
    () =>
      apiClient.productLookupItems.getSubCategoryChoices(formattedCategoryIds),
    {
      enabled: !!user?.id && !!user?.accessToken && !!merchantId,
    }
  );
};
