import {
  Button,
  Chip,
  Container,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { useState } from 'react';
import { InviteDrawer, TeamTable } from './components';
import {
  removeTeamMember,
  resendInviteToTeamMember,
  useAuth,
  useMerchantTeamMembers,
} from '@/hooks';
import { MerchantUser, UserRole } from '@/types';
import { validateRolesByMerchantId } from '@/utils/userUtils';
import AlertDialog from '@/components/AlertDialog/AlertDialog';

export function MerchantTeamsModule(): JSX.Element {
  const theme = useTheme();
  const { data: user } = useAuth();
  const { data: teams, isLoading, refetch } = useMerchantTeamMembers();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [selectedMember, setSelectedMember] = useState<MerchantUser>();

  const onSuccessRemovingTeamMember = () => {
    refetch();
    setSelectedMember(undefined);
    setShowAlertDialog(false);
  };
  const onErrorRemovingTeamMember = () => {
    setShowAlertDialog(false);
  };
  // TODO: error messages

  const { mutate: onResendInviteClick } = resendInviteToTeamMember();
  const { mutate: submitRemoveTeamMember } = removeTeamMember(
    onSuccessRemovingTeamMember,
    onErrorRemovingTeamMember
  );

  const onRemoveClick = (merchantUser: MerchantUser) => {
    setShowAlertDialog(true);
    setSelectedMember(merchantUser);
  };

  const handleAlertDialogClose = (selected: boolean) => {
    if (selected && selectedMember) {
      submitRemoveTeamMember(selectedMember.userId);
    } else {
      setShowAlertDialog(false);
    }
  };

  const memberRoles = [{ label: 'Admin', value: UserRole.ADMIN }];
  const isAuthorizedToSendInvite =
    validateRolesByMerchantId(
      user,
      [UserRole.OWNER, UserRole.SP_INTERNAL, UserRole.INSPLB_ADMIN],
      user?.primaryMerchantId
    ) || false;
  const alertDialog = {
    title: (teamMemberName: string) =>
      `Are you sure you want to remove ${teamMemberName}?`,
    image: undefined,
    message:
      'Clicking the button "Confirm" below will terminate the selected Team Members access to your business account.',
  };

  return (
    <>
      <Container>
        {showAlertDialog && (
          <AlertDialog
            open={showAlertDialog}
            onClose={handleAlertDialogClose}
            image={alertDialog.image}
            title={alertDialog.title(
              selectedMember?.user?.firstName || 'your team member'
            )}
            message={alertDialog.message}
            agree="Confirm"
            disagree="Cancel"
          />
        )}
        <Stack justifyContent="center" marginTop={theme.spacers.xxl4}>
          <Stack
            direction="row"
            justifyContent="space-between"
            marginBottom={theme.spacers.xxl}
          >
            <Stack
              direction="row"
              spacing={theme.spacers.s}
              alignItems="center"
            >
              <Typography variant="h4">My team</Typography>
              <Chip
                label={
                  <Typography variant="button">
                    {!isLoading ? teams?.length : <Skeleton />}
                  </Typography>
                }
                size="medium"
                color="default"
              />
            </Stack>
            {isAuthorizedToSendInvite && (
              <Button
                data-testid="inviteMemberBtn"
                onClick={() => setDrawerOpen(true)}
              >
                Invite members
              </Button>
            )}
          </Stack>

          <TeamTable
            isLoading={isLoading}
            user={user}
            teamMembers={teams}
            onResendInviteClick={onResendInviteClick}
            onRemoveClick={onRemoveClick}
          />
        </Stack>
      </Container>

      <InviteDrawer
        open={drawerOpen}
        rolesDropdown={memberRoles}
        onClose={() => {
          setDrawerOpen(false);
        }}
        onSubmit={() => {
          refetch({ cancelRefetch: true });
        }}
      />
    </>
  );
}
