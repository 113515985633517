import { useQuery } from '@tanstack/react-query';
import { ApiClient } from '../api/apiClient';
import { Booking, RefundBookingDto } from '@/types';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

export const useBooking = (bookingId: string) => {
  const apiClient = ApiClient();

  const getBookingById = async (): Promise<Booking> => {
    return await apiClient.bookings.getBookingById({ bookingId });
  };

  return useQuery<Booking>(['booking', bookingId], () => getBookingById());
};

export const useRefundBookingMutation = (
  onSuccess?: () => void,
  onError?: (error: Error) => void
) => {
  const apiClient = ApiClient();

  const refundBookingMutation = useMutation({
    mutationFn: async ({
      bookingId,
      refundBookingDto,
    }: {
      bookingId: string;
      refundBookingDto: RefundBookingDto;
    }) => {
      await apiClient.bookings.refundBooking(bookingId, refundBookingDto);
    },
    onSuccess: () => {
      onSuccess && onSuccess();
    },
    onError: (error: AxiosError) => {
      console.error('Error in refund mutation:', error);
      onError && onError(error);
    },
  });

  return refundBookingMutation;
};
