import { BuildingSelect, MerchantUser, User, PurchasableType } from '@/types';
import {
  MerchantProfile,
  CreateNewTeamMemberDto,
  SendTeamMemberInviteDto,
  RemoveTeamMemberDto,
  SwitchPrimaryMerchantDto,
} from '@/types/Merchant';
import { AxiosInstance } from 'axios';

export const endpoints = {
  merchants: '/merchants',
  getMerchant({ merchantId }: { merchantId: number }) {
    return `/merchants/${merchantId}`;
  },
  getMerchantServiceableBuildings({ merchantId }: { merchantId: number }) {
    return `/merchants/${merchantId}/serviceable-buildings`;
  },
  getMerchantPlatformFee({ merchantId }: { merchantId: number }) {
    return `/merchants/${merchantId}/platform-fee`;
  },
  getMerchantTeamMembers({ merchantId }: { merchantId: number }) {
    return `/merchants/${merchantId}/team-members`;
  },
  createMerchantTeamMember: '/merchants/add-team-member',
  sendTeamMemberInvite: '/merchants/send-invite',
  removeTeamMember: '/merchants/remove-team-member',
  switchPrimaryMerchant: '/merchants/switch-primary-merchant',
};

export const merchants = ({
  axiosInstance,
}: {
  axiosInstance: AxiosInstance;
}) => {
  return {
    getMerchantProfile: async ({
      merchantId,
    }: {
      merchantId: number;
    }): Promise<MerchantProfile> => {
      const { data } = await axiosInstance.get(
        endpoints.getMerchant({ merchantId })
      );
      return data;
    },
    getMerchantServiceableBuildings: async ({
      merchantId,
    }: {
      merchantId: number;
    }): Promise<BuildingSelect[]> => {
      const { data } = await axiosInstance.get(
        endpoints.getMerchantServiceableBuildings({ merchantId })
      );
      return data;
    },
    updateMerchantProfile: async ({
      merchantId,
      updateMerchantProfile,
    }: {
      merchantId: number;
      updateMerchantProfile: Partial<MerchantProfile>;
    }): Promise<MerchantProfile> => {
      const { data } = await axiosInstance.patch(
        endpoints.getMerchant({ merchantId }),
        updateMerchantProfile
      );
      return data;
    },
    getMerchantPlatformFee: async ({
      merchantId,
      purchasableType,
    }: {
      merchantId: number;
      purchasableType?: PurchasableType;
    }): Promise<number> => {
      const { data } = await axiosInstance.get(
        endpoints.getMerchantPlatformFee({ merchantId }),
        { params: { purchasableType } }
      );
      return data;
    },
    getMerchantTeamMembers: async ({
      merchantId,
    }: {
      merchantId: number;
    }): Promise<MerchantUser[]> => {
      const { data } = await axiosInstance.get(
        endpoints.getMerchantTeamMembers({ merchantId })
      );
      return data;
    },
    createMerchantTeamMember: async (
      createNewTeamMemberDto: CreateNewTeamMemberDto
    ): Promise<User> => {
      const { data } = await axiosInstance.post(
        endpoints.createMerchantTeamMember,
        createNewTeamMemberDto
      );
      return data;
    },
    sendTeamMemberInvite: async (
      sendTeamMemberInviteDto: SendTeamMemberInviteDto
    ): Promise<boolean> => {
      const { data } = await axiosInstance.put(
        endpoints.sendTeamMemberInvite,
        sendTeamMemberInviteDto
      );
      return data;
    },
    removeTeamMember: async (
      removeTeamMemberDto: RemoveTeamMemberDto
    ): Promise<boolean> => {
      const { data } = await axiosInstance.put(
        endpoints.removeTeamMember,
        removeTeamMemberDto
      );
      return data;
    },
    switchPrimaryMerchant: async (
      switchPrimaryMerchantDto: SwitchPrimaryMerchantDto
    ): Promise<User> => {
      const { data } = await axiosInstance.post(
        endpoints.switchPrimaryMerchant,
        switchPrimaryMerchantDto
      );
      return data;
    },
  };
};
