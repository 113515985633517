import { Booking } from './Booking';
import { Consumer } from './Consumer';
import { Experience } from './Experience';
import { Listing } from './Listing';

export enum BookingRequestStatus {
  PENDING_MERCHANT = 'PENDING_MERCHANT',
  PENDING_CONSUMER = 'PENDING_CONSUMER',
  ACCEPTED = 'ACCEPTED',
  CANCELLED = 'CANCELLED',
  REJECTED = 'REJECTED',
  DELETED = 'DELETED',
}
// Made it as object to make it more flexible for future changes
export interface RequestInfo {
  message: string;
}

export interface BookingRequest {
  id: string;
  consumerId: string;
  consumer?: Consumer;
  productId: string;
  listingId?: string; // This is listing id that refers to a quote
  bookingId?: string; // We assume one listing and one booking for now
  fullName?: string; // computed field for consumer name only available with pagination
  membershipPlanId?: string; // Membership plan id if booking request requires membership purchase
  membershipPlanPurchaseId?: string; // Membership plan purchase id if purchased membership plan
  historicalListingIds?: string[]; // This is the listing history ids that refers to a quote
  historicalBookingIds?: string[]; // This is the booking history ids that refers to a quote
  status: BookingRequestStatus;
  offerAt?: Date;
  acceptedAt?: Date; // This is the date when the booking request is accepted
  requestInfo: RequestInfo;
  propertyManagerId?: string;

  createdUserId: string;
  createdAt: Date;
  updatedUserId?: string;
  updatedAt?: Date;
  deletedUserId?: string;
  deletedAt?: Date;
}

export interface BookingRequestLoaded
  extends Omit<BookingRequest, 'bookingId' | 'listingId' | 'productId'> {
  id: string;
  booking?: Booking; // This is the booking object that refers to a quote
  listing?: Listing; // This is the listing object that refers to a quote
  product?: Experience;
}
