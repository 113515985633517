import {
  Grid,
  Typography,
  Checkbox,
  Menu,
  MenuItem,
  Paper,
} from '@silverstein-properties/inspirelabs-ui';
import RoundButton from 'components/RoundButton/Component';
import { useState } from 'react';
import { ExperienceStatus } from '../../types';
import { StyledImage } from './MiniCard.styles';
import Graffiti from './graffiti.png';
import { formatDate } from '@/utils';
export interface MiniCardProps {
  image?: string;
  title: string;
  supportingText?: string;
  status: string;
  date: string;
  time: string;
  disableActions?: boolean;
  id?: string;
  nbOfGuests?: string;
  nbOfCancellations?: string;
  miniCardType?: 'experience' | 'event' | 'simple';
  isSelected?: boolean;
  isReadOnly?: boolean;
  isRecurring?: boolean;
  onEdit?: () => void;
  onView?: () => void;
  onCalendarView?: (id: string) => void;
  onSelect?: (id: string, state: Boolean) => void;
}
import { RepeatIcon, Tooltip } from '@silverstein-properties/inspirelabs-ui';

export default function MiniCard({
  date,
  time,
  disableActions,
  image,
  status,
  supportingText,
  title,
  id,
  nbOfGuests,
  nbOfCancellations,
  miniCardType,
  isSelected = false,
  isReadOnly = false,
  isRecurring = false,
  onEdit,
  onView,
  onCalendarView,
  onSelect,
}: MiniCardProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isInProgress = status === ExperienceStatus[0];
  const isPendingApproval = status === ExperienceStatus[1];
  const isApproved = status === ExperienceStatus[3];

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    onEdit && onEdit();
    handleClose();
  };

  const handleView = () => {
    onView && onView();
    onCalendarView && id ? onCalendarView(id) : null;
    handleClose();
  };

  const [selected, setSelected] = useState(isSelected || false);

  if (miniCardType === 'simple') {
    const toggleSelection = () => {
      if (!isReadOnly) {
        onSelect && onSelect(id || '', !selected);
        setSelected(!selected);
      }
    };

    return (
      <Paper variant="outlined" sx={{ p: 2, width: '100%', maxWidth: '838px' }}>
        <div onClick={toggleSelection} style={{ cursor: 'pointer' }}>
          <Grid
            container
            alignItems="center"
            maxWidth="838px"
            width="100%"
            spacing={2}
          >
            {/* Checkbox for selection */}
            <Grid item>
              <Checkbox
                checked={selected}
                onChange={e => {
                  // Prevent the outer div's click event from firing
                  e.stopPropagation();
                  toggleSelection();
                }}
                disabled={isReadOnly}
              />
            </Grid>

            <Grid item>
              <StyledImage
                src={image || Graffiti}
                alt={title}
                height={80}
                width={120}
              />
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" align="left">
                {title}
              </Typography>
              {supportingText ? (
                <Typography variant="body2" align="left">
                  {supportingText}
                </Typography>
              ) : null}
            </Grid>
          </Grid>
        </div>
      </Paper>
    );
  }

  if (miniCardType === 'event') {
    return (
      <Paper variant="outlined" sx={{ p: 2 }}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <StyledImage
              src={image || Graffiti}
              alt={title}
              height={80}
              width={120}
            />
          </Grid>
          <Grid item xs>
            <Typography variant="subtitle1">{title}</Typography>
            {supportingText ? (
              <Typography variant="body2">{supportingText}</Typography>
            ) : null}
          </Grid>
          <Grid item xs display="flex" justifyContent="flex-end">
            <Typography variant="body2">{`${nbOfGuests} guest${
              nbOfGuests === '1' ? '' : 's'
            }`}</Typography>
          </Grid>
          <Grid item xs display="flex" justifyContent="flex-end">
            <Typography variant="body2" color="error">
              {nbOfCancellations ? `${nbOfCancellations} cancelled` : null}
            </Typography>
          </Grid>
          <Grid
            item
            xs
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
          >
            <div className="timeListBlock">
              <Typography variant="body2" style={{ display: 'none' }}>
                {formatDate(date)}
              </Typography>
              <Typography variant="body2">{time}</Typography>
              {isRecurring && (
                <Tooltip
                  arrow
                  title="This is a recurring event."
                  placement="top"
                >
                  <RepeatIcon
                    width={16}
                    color="primary"
                    style={{ marginLeft: '5px' }}
                  />
                </Tooltip>
              )}
            </div>
            <Typography
              variant="body2"
              color={status === 'Cancelled' ? 'error' : 'feedbackGreen.main'}
            >
              {status}
            </Typography>
          </Grid>
          <Grid item xs sx={{ '>*': { float: 'right' } }}>
            {!disableActions ? (
              <>
                <RoundButton
                  text={isInProgress ? 'Finish' : 'Actions'}
                  default={`${isInProgress ? 'blue' : 'white-navy'} small`}
                  hover={`${isInProgress ? 'white-navy' : 'blue'} small`}
                  handleClick={e => {
                    isInProgress && onEdit ? onEdit() : handleClick(e);
                  }}
                />
                {!isInProgress ? (
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    sx={{ mt: 1 }}
                  >
                    {!isPendingApproval ? (
                      <MenuItem
                        disableRipple
                        onClick={isInProgress ? handleEdit : handleView}
                      >
                        View / Edit
                      </MenuItem>
                    ) : null}
                    {!isApproved ? (
                      <MenuItem disableRipple onClick={handleClose}>
                        Delete
                      </MenuItem>
                    ) : null}
                  </Menu>
                ) : null}
              </>
            ) : null}
          </Grid>
        </Grid>
      </Paper>
    );
  } else {
    return (
      <Paper variant="outlined" sx={{ p: 2 }}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <StyledImage
              src={image || Graffiti}
              alt={title}
              height={80}
              width={120}
            />
          </Grid>
          <Grid item xs>
            <Typography variant="subtitle1" sx={{ wordBreak: 'break-word' }}>
              {title}
            </Typography>
            {supportingText ? (
              <Typography variant="body2">{supportingText}</Typography>
            ) : null}
          </Grid>
          <Grid item xs display="flex" justifyContent="flex-end">
            <Typography
              color={isPendingApproval ? 'error' : 'text'}
              variant="body2"
            >
              {status}
            </Typography>
          </Grid>
          <Grid
            item
            xs
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
          >
            <Typography variant="body2">{date}</Typography>
            <Typography variant="body2">{time}</Typography>
          </Grid>
          <Grid item xs sx={{ '>*': { float: 'right' } }}>
            {!disableActions ? (
              <>
                <RoundButton
                  text={isInProgress ? 'Finish' : 'Actions'}
                  default={`${isInProgress ? 'blue' : 'white-navy'} small`}
                  hover={`${isInProgress ? 'white-navy' : 'blue'} small`}
                  handleClick={e => {
                    isInProgress && onEdit ? onEdit() : handleClick(e);
                  }}
                />
                {!isInProgress ? (
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    sx={{ mt: 1 }}
                  >
                    {!isPendingApproval ? (
                      <MenuItem
                        disableRipple
                        onClick={isInProgress ? handleEdit : handleView}
                      >
                        View / Edit
                      </MenuItem>
                    ) : null}
                    {!isApproved ? (
                      <MenuItem disableRipple onClick={handleClose}>
                        Delete
                      </MenuItem>
                    ) : null}
                  </Menu>
                ) : null}
              </>
            ) : null}
          </Grid>
        </Grid>
      </Paper>
    );
  }
}
