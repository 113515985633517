import { ApiClient } from '@/api/apiClient';
import { useAuth } from './useAuth';
import { useQuery } from '@tanstack/react-query';

export const useIsSuperAdmin = () => {
  const { data: user } = useAuth();
  const apiClient = ApiClient();

  return useQuery({
    queryKey: ['isSuperAdmin'],
    queryFn: () =>
      apiClient.users.isSuperAdmin({ userId: user?.id.toString() || '' }),
    enabled: !!user && !!user.id,
  });
};
