import {
  Grid,
  styled,
  Typography,
} from '@silverstein-properties/inspirelabs-ui';

export const RefundDialogContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(5),
}));

export const StyledDialogTitle = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  textAlign: 'left',
}));

export const StyledDialogActions = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
