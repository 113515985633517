import { DataTableLoading, Pagination } from '@/components';
import { DEFAULT_SEARCH_PARAMS, SEARCH_BOX } from '@/constants';
import {
  useBookingRequestLoaded,
  useGetAllBookingRequestsLoaded,
  useMembershipPlan,
} from '@/hooks';
import { BookingRequestStatus, Experience, PriceType } from '@/types';
import {
  Button,
  DataTable,
  DataTableEmpty,
  InputField,
  SearchIcon,
  Stack,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { DetailsDrawer } from '@/components';
import {
  PaginationState,
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { getTableColumns } from './BookingRequestTable.columns';
import { useLocation, useNavigate } from 'react-router';
import { ViewQuote } from '@/modules/CommonModule';

export type BookingRequestSectionPropsType = {
  experience: Experience;
};

export const BookingRequestSection = ({
  experience,
}: BookingRequestSectionPropsType) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: DEFAULT_SEARCH_PARAMS.PAGE,
    pageSize: DEFAULT_SEARCH_PARAMS.PAGE_SIZE,
  });
  const [selectedPurchaseId, setSelectedPurchaseId] = useState<string>();
  const [viewQuoteDrawerOpen, setViewQuoteDrawerOpen] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const { data: bookingRequests, isLoading } = useGetAllBookingRequestsLoaded({
    productId: experience.id,
    search: debouncedSearchTerm,
    pagination: {
      page: pagination.pageIndex,
      pageSize: pagination.pageSize,
      orderBy: sorting.map(sort => ({
        id: sort.id,
        type: sort.desc ? 'desc' : 'asc',
      })),
    },
  });

  // Load booking request when selected
  const { data: bookingRequestLoaded } = useBookingRequestLoaded(
    selectedPurchaseId || '',
    !!selectedPurchaseId
  );
  // Load membership plan when booking request is selected
  const { data: membershipPlan } = useMembershipPlan({
    membershipPlanId: bookingRequestLoaded?.membershipPlanId,
  });

  // Open view quote drawer if bookingRequestId is passed in location state
  useEffect(() => {
    if (location.state?.bookingRequestId) {
      setSelectedPurchaseId(location.state?.bookingRequestId);
      setViewQuoteDrawerOpen(true);
    }
  }, []);

  // Debounce search term
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    // Reset pagination to first page when search term changes
    setPagination(prevState => ({ ...prevState, pageIndex: 0 }));
  };

  const onMemberDetailsClick = (action: 'viewQuote' | 'view', id: string) => {
    switch (action) {
      case 'viewQuote':
        setSelectedPurchaseId(id);
        setViewQuoteDrawerOpen(true);
        break;
      case 'view':
        navigate('/messages', {
          state: { purchaseId: id },
        });
        break;
    }
  };

  const onViewQuoteWindowClose = () => {
    setViewQuoteDrawerOpen(false);
    setSelectedPurchaseId(undefined);
  };

  const onViewMembershipDetailsClick = () => {
    navigate(`/memberships/${membershipPlan?.subscriptionId}#members`, {
      state: {
        planPurchaseId: bookingRequestLoaded?.membershipPlanPurchaseId,
      },
    });
  };

  const pageCount = bookingRequests?.metadata?.totalItems
    ? Math.ceil(bookingRequests?.metadata?.totalItems / pagination.pageSize)
    : -1;
  const rightAlignedColumns = ['statusDisplay'];

  const table = useReactTable({
    data: bookingRequests?.data || [],
    columns: getTableColumns(onMemberDetailsClick),
    pageCount: pageCount,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    columnResizeMode: 'onChange',
    onPaginationChange: setPagination,
    manualPagination: true,
  });

  const drawerActions =
    bookingRequestLoaded?.listing?.basePrice?.priceType ===
      PriceType.MEMBERSHIP &&
    bookingRequestLoaded.status === BookingRequestStatus.ACCEPTED ? (
      <Button
        variant="text"
        onClick={onViewMembershipDetailsClick}
        sx={{ marginY: theme => theme.spacing(2) }}
      >
        View membership details
      </Button>
    ) : undefined;

  return (
    <>
      <DetailsDrawer
        actions={drawerActions}
        closeWindow={onViewQuoteWindowClose}
        drawerOpen={viewQuoteDrawerOpen}
        title="Quote details"
      >
        <ViewQuote
          bookingRequestId={selectedPurchaseId || ''}
          deleteQuoteErrorMessage=""
        />
      </DetailsDrawer>
      <Stack spacing={4}>
        <InputField
          label="Search members"
          placeholder="Search ..."
          value={searchTerm}
          onChange={handleSearchChange}
          showFloatingLabel={false}
          notched={false}
          fullWidth={false}
          startAdornment={
            <SearchIcon
              width={theme.constants.ICON_SIZE.small}
              style={{ marginRight: theme.spacers.s }}
            />
          }
          sx={{
            borderRadius: theme.constants.BORDER_RADIUS.large,
            width: `${SEARCH_BOX.WIDTH_SHORT}px`,
            height: `${SEARCH_BOX.HEIGHT}px`,
            marginBottom: theme.spacers.m,
          }}
        />
        <DataTable table={table} rightAlignedColumns={rightAlignedColumns} />
        <DataTableLoading
          show={isLoading}
          numberOfSkeletons={pagination.pageSize}
        />
        <DataTableEmpty
          show={!isLoading && bookingRequests?.data.length === 0}
        />
        <Pagination
          show={true}
          table={table}
          totalItems={bookingRequests?.metadata?.totalItems || 0}
        />
      </Stack>
    </>
  );
};
