import { LabelField } from '@/components';
import { CANCELLATION_OPTIONS, SERVICE_TYPE_CHOICES } from '@/constants';
import {
  useBookingRequestLoaded,
  useMainCommunicationConversation,
  useMembershipPlan,
  useTaxRate,
} from '@/hooks';
import {
  calculatePriceBreakdown,
  capitalizeEachWord,
  formatDateWithDayOfWeek,
  formatPricing,
  formatTime,
  pluralize,
} from '@/utils';
import {
  Box,
  Grid,
  MessageBanner,
  Stack,
  Typography,
} from '@silverstein-properties/inspirelabs-ui';
import { QuoteSkeleton } from '../QuoteSkeleton';
import { HostList } from '@/modules/SchedulingModule/components';
import { useEffect, useState } from 'react';
import { QuotePriceBreakdown } from '../QuotePriceBreakdown';
import { PriceType, PriceTypeLabel } from '@/types';

export type ViewQuoteProps = {
  bookingRequestId: string;
  deleteQuoteErrorMessage: string;
};

export const ViewQuote = ({
  bookingRequestId,
  deleteQuoteErrorMessage,
}: ViewQuoteProps) => {
  const { data: bookingRequestLoaded, isLoading: isBookingRequestLoading } =
    useBookingRequestLoaded(bookingRequestId, !!bookingRequestId);
  const [taxRate, setTaxRate] = useState<number | undefined>();
  const { data: taxRateData, isLoading: taxRateIsLoading } =
    useTaxRate(taxRate);
  const {
    data: mainCommunicationThread,
    isLoading: isMainCommunicationThreadLoading,
  } = useMainCommunicationConversation(bookingRequestId);

  const isPricingTypeMembership =
    bookingRequestLoaded?.listing?.basePrice?.priceType ===
    PriceType.MEMBERSHIP;
  const { data: membershipPlan } = useMembershipPlan({
    membershipPlanId:
      bookingRequestLoaded?.listing?.basePrice?.membershipPlanId,
  });

  useEffect(() => {
    setTaxRate(bookingRequestLoaded?.listing?.basePrice?.taxRateId);
  }, [bookingRequestLoaded]);

  if (
    isBookingRequestLoading ||
    isMainCommunicationThreadLoading ||
    taxRateIsLoading
  ) {
    return <QuoteSkeleton />;
  }

  if (!bookingRequestId || !bookingRequestLoaded) {
    return null;
  }

  const listing = bookingRequestLoaded.listing;

  if (!listing || !listing?.basePrice) {
    return null;
  }

  const isPriceFlatRate = listing.basePrice.priceType === PriceType.FLAT_FEE;
  const guestPrice = isPriceFlatRate
    ? listing?.basePrice?.totalAmount
    : listing?.basePrice?.totalAmount * (listing.capacity?.max || 1);

  const priceBreakdown = calculatePriceBreakdown({
    feePercentage: listing.basePrice.feePercentage,
    price: guestPrice,
    taxRate: taxRateData,
  });

  return (
    <Box id="viewQuote" sx={{ gap: 0 }}>
      {!!deleteQuoteErrorMessage && (
        <Box sx={{ mb: 2 }}>
          <MessageBanner type="error">{deleteQuoteErrorMessage}</MessageBanner>
        </Box>
      )}
      <Stack spacing={5} sx={{ width: '100%' }}>
        <Typography variant="h5">
          {
            mainCommunicationThread?.mainCommunicationCard
              .communicationPurchaseName
          }
        </Typography>
        <LabelField
          label="Prepared for"
          value={
            <Typography data-testid="addQuote_consumer">
              {mainCommunicationThread?.mainCommunicationCard.consumerName}
            </Typography>
          }
        />
        <LabelField
          label="Date"
          value={
            <Typography data-testid="addQuote_consumer">
              {formatDateWithDayOfWeek(listing?.startsAt || '')}
            </Typography>
          }
        />
        <LabelField
          label="Time"
          value={`${formatTime(listing?.startsAt)} - ${formatTime(
            listing?.endsAt
          )} `}
        />
        <LabelField
          label="Number of guests"
          value={<Typography>{listing?.capacity?.max}</Typography>}
        />
        <LabelField
          label="Location"
          value={
            <>
              <Typography>
                {
                  SERVICE_TYPE_CHOICES.find(
                    serviceType =>
                      serviceType.value ===
                      listing?.meetingPoint?.environmentType
                  )?.label
                }
              </Typography>
              <Typography>{listing?.meetingPoint?.street}</Typography>
              <Typography>{listing?.meetingPoint?.apartment}</Typography>
              <Typography>{`${capitalizeEachWord(
                listing?.meetingPoint?.city || '',
                true
              )} ${(listing?.meetingPoint?.state || '').toUpperCase()} ${
                listing?.meetingPoint?.zipCode || ''
              }`}</Typography>
            </>
          }
        />
        {listing?.host && (
          <LabelField
            label="Host"
            value={<HostList hosts={[listing?.host]} />}
          />
        )}
        <LabelField
          label="Details"
          value={<Typography>{listing?.description}</Typography>}
        />
        <LabelField
          label="Cancellation Policy"
          value={
            <>
              <Typography>
                {CANCELLATION_OPTIONS.find(
                  policy =>
                    policy.value === listing.refundPolicy?.cancellationPolicy
                )?.label || 'No cancelation policy selected'}
              </Typography>
              <Typography>
                Cancelation before the selected timeframe is fully refundable.
                Cancelation after the selected timeframe is non-refundable.
              </Typography>
            </>
          }
        />
        <LabelField
          label="Pricing"
          value={
            <>
              {!isPricingTypeMembership ? (
                <Typography>
                  {formatPricing(
                    listing.basePrice?.totalAmount || listing.price,
                    2
                  )}{' '}
                  {listing.basePrice.priceType &&
                    PriceTypeLabel[
                      PriceType[
                        listing.basePrice
                          .priceType as keyof typeof PriceTypeLabel
                      ]
                    ].toLowerCase()}
                </Typography>
              ) : (
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography>{membershipPlan?.name}</Typography>
                  </Grid>
                  <Grid item xs="auto">
                    <Typography>{`${formatPricing(
                      membershipPlan?.totalAmount || 0
                    )}/${membershipPlan?.billingCycleType}`}</Typography>
                    <Typography>{`per ${pluralize(
                      membershipPlan?.maxNbOfConsumers || 0,
                      'member'
                    )}`}</Typography>
                  </Grid>
                </Grid>
              )}
            </>
          }
        />
        {!isPricingTypeMembership ? (
          <QuotePriceBreakdown {...priceBreakdown} />
        ) : null}
      </Stack>
    </Box>
  );
};
