import {
  Stack,
  Typography,
  Box,
  Grid,
  MenuItem,
  Select,
  InputField,
} from '@silverstein-properties/inspirelabs-ui';
import { Controller, useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { Experience } from '@/types';
import { LabelField } from '@/components';
import MiniFileInput from '@/components/FormTextInput/MiniFileInput';
import { useAuth } from '@/hooks';
import { omit } from 'lodash';
import { TEXT_AREA_LIMIT_LONG, TEXT_AREA_LIMIT_SHORT } from '@/constants';
import { EXPERIENCE_YEARS_CHOICES } from '@/constants';
import { AboutHostSectionEditInput } from '@/classes';
import { FormHelperText } from '@mui/material';
import { renderInputFieldHelperText } from '@/utils';

const resolver = classValidatorResolver(AboutHostSectionEditInput);

export type AboutHostSectionEditPropsType = {
  experience: Experience;
  onFormSubmit: (data: AboutHostSectionEditInput) => Promise<void>;
};

export const AboutHostSectionEdit = ({
  experience,
  onFormSubmit,
}: AboutHostSectionEditPropsType) => {
  const { data: user } = useAuth();
  const {
    watch,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<AboutHostSectionEditInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      firstName: experience.host?.firstName || user?.firstName || '',
      lastName: experience.host?.lastName || user?.lastName || '',
      hostPhoto: experience.host?.hostPhoto || '',
      phoneNumber: experience.host?.phoneNumber || '',
      bio: experience.host?.bio || '',
      operatingYears: experience.host?.operatingYears || '',
    },
  });
  const onPhotoChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    onChangeCallback: (e: '' | File) => void
  ) => {
    if (e instanceof File) {
      onChangeCallback(e);
    } else {
      const newFile = e.target?.files ? e.target.files[0] : '';
      onChangeCallback(newFile);
    }
  };

  const onPhotoReset = () => {};
  return (
    <Stack spacing={4}>
      <Typography variant="h5">About the host</Typography>
      <form id="aboutTheHostForm" onSubmit={handleSubmit(onFormSubmit)}>
        <Stack spacing={4}>
          <LabelField
            label="Name"
            value={
              <Stack flexDirection="row" mt={2}>
                <Controller
                  control={control}
                  name="firstName"
                  render={({ field }) => (
                    <InputField
                      {...field}
                      data-testid="hostFirstName"
                      label="First name"
                      error={!!errors.firstName}
                      helperText={renderInputFieldHelperText(
                        watch('firstName')?.length,
                        TEXT_AREA_LIMIT_SHORT,
                        errors.firstName?.message
                      )}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="lastName"
                  render={({ field }) => (
                    <InputField
                      {...field}
                      data-testid="hostLastName"
                      label="Last name"
                      error={!!errors.lastName}
                      helperText={renderInputFieldHelperText(
                        watch('lastName')?.length,
                        TEXT_AREA_LIMIT_SHORT,
                        errors.lastName?.message
                      )}
                    />
                  )}
                />
              </Stack>
            }
          />
          <LabelField
            label="Photo"
            value={
              <Grid
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 2,
                  mt: 1,
                }}
              >
                <Box data-testid="aboutTheHostPhoto">
                  <Controller
                    name="hostPhoto"
                    control={control}
                    render={({ field }) => (
                      <MiniFileInput
                        {...omit(field, 'ref')}
                        value={field.value as string | File}
                        keyNumber="1"
                        handleChange={e => onPhotoChange(e, field.onChange)}
                        reset={onPhotoReset}
                      />
                    )}
                  />
                </Box>
                <Box>
                  <Typography variant="body2">
                    Please provide a clear photo of your face
                  </Typography>
                </Box>
              </Grid>
            }
          />
          <Controller
            control={control}
            name="phoneNumber"
            render={({ field }) => (
              <InputField
                {...field}
                data-testid="hostPhone"
                label="Phone"
                error={!!errors.phoneNumber}
                helperText={
                  !errors.phoneNumber ? ' ' : errors.phoneNumber?.message
                }
              />
            )}
          />
          <LabelField
            label="Bio"
            value={
              <Box mt={2}>
                <Controller
                  control={control}
                  name="bio"
                  render={({ field }) => (
                    <InputField
                      {...field}
                      data-testid="aboutTheHostBio"
                      label="Bio"
                      multiline
                      minRows={6}
                      error={!!errors.bio}
                      helperText={renderInputFieldHelperText(
                        watch('bio')?.length,
                        TEXT_AREA_LIMIT_LONG,
                        errors.bio?.message
                      )}
                    />
                  )}
                />
              </Box>
            }
          />

          <LabelField
            label="Length of service"
            data-testid="aboutTheHost.operatingYears"
            value={
              <Box mt={2}>
                <Controller
                  name="operatingYears"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        data-testid="aboutTheHostOperatingYears"
                        displayEmpty={true}
                        error={!!errors.operatingYears}
                        renderValue={(selected: string) => {
                          if (!selected) {
                            return 'Year Started';
                          }
                          const selectedOperatingYears =
                            EXPERIENCE_YEARS_CHOICES.find(
                              operatingYears =>
                                operatingYears.value === selected
                            );
                          return selectedOperatingYears?.label || '';
                        }}
                      >
                        {EXPERIENCE_YEARS_CHOICES.map(operatingYears => (
                          <MenuItem
                            key={operatingYears.id}
                            value={operatingYears.value}
                          >
                            {operatingYears.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText error={!!errors.operatingYears}>
                        {!!errors.operatingYears
                          ? errors.operatingYears?.message
                          : ''}
                      </FormHelperText>
                    </>
                  )}
                />
              </Box>
            }
          />
        </Stack>
      </form>
    </Stack>
  );
};
