import { Price } from './Price';

export enum AmenityType {
  INCLUDED = 'included', // Included in the booking
  ADD_ON = 'add-on', // Add on with user defined cost
  CUSTOM_ADD_ON = 'custom-add-on', // Custom add on with user defined cost
}

export enum AmenityPriceType {
  PER_HOUR = 'PER_HOUR',
  PER_DAY = 'PER_DAY',
  PRICE_PER_PERSON = 'PRICE_PER_PERSON',
  FLAT_FEE = 'FLAT_FEE',
}

export interface AmenityPrice
  extends Omit<
    Price,
    | 'id'
    | 'priceType'
    | 'createdUserId'
    | 'createdAt'
    | 'updatedUserId'
    | 'updatedAt'
    | 'deletedUserId'
    | 'deletedAt'
  > {
  priceType: AmenityPriceType;
}

export interface Amenity {
  name: string;
  icon: string;
  type: AmenityType;
  pricing: AmenityPrice;
}
