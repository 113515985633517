import {
  Stack,
  Typography,
  InputField,
  Box,
  Select,
  MenuItem,
} from '@silverstein-properties/inspirelabs-ui';
import { LabelField } from '@/components';
import { Controller, useForm } from 'react-hook-form';
import { SquareConnectionSectionEditInput } from '@/classes';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { Experience, SquareEnvironment } from '@/types';
import { SQUARE_ENVIRONMENT } from '@/constants';
import { FormHelperText } from '@mui/material';

const resolver = classValidatorResolver(SquareConnectionSectionEditInput, {
  transformer: { enableImplicitConversion: true },
});

export type SquareConnectionSectionEditPropsType = {
  experience: Experience;
  onFormSubmit: (data: SquareConnectionSectionEditInput) => Promise<void>;
};

export const SquareConnectionSectionEdit = ({
  experience,
  onFormSubmit,
}: SquareConnectionSectionEditPropsType) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<SquareConnectionSectionEditInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      squareConfiguration: {
        clientEnvironment:
          experience?.squareConfiguration?.clientEnvironment ||
          SquareEnvironment.Production,
        locationId: experience.squareConfiguration?.locationId || '',
        accessToken: experience.squareConfiguration?.accessToken || '',
      },
    },
  });

  const onSquareConnectionFormSubmit = async (
    data: SquareConnectionSectionEditInput
  ) => {
    await onFormSubmit(data);
  };

  return (
    <Stack spacing={4}>
      <Typography variant="h5">Square Connection</Typography>
      <form
        id="squareConnectionForm"
        onSubmit={handleSubmit(onSquareConnectionFormSubmit)}
      >
        <Stack width="100%" spacing={4}>
          <LabelField
            label="Square Environment"
            value={
              <Box mt={2}>
                <Controller
                  name="squareConfiguration.clientEnvironment"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        fullWidth
                        data-testid="squareClientEnvironment"
                        displayEmpty={true}
                        error={!!errors.squareConfiguration?.clientEnvironment}
                        renderValue={(selected: string) => {
                          if (!selected) {
                            return 'Select Square Environment';
                          }
                          const selectedEnvironment = SQUARE_ENVIRONMENT.find(
                            environment => environment.value === selected
                          );
                          return selectedEnvironment?.label || '';
                        }}
                      >
                        {SQUARE_ENVIRONMENT.map(environment => (
                          <MenuItem
                            key={environment.id}
                            value={environment.value}
                          >
                            {environment.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText
                        error={!!errors.squareConfiguration?.clientEnvironment}
                      >
                        {!!errors.squareConfiguration?.clientEnvironment
                          ? errors.squareConfiguration?.clientEnvironment
                              .message
                          : ''}
                      </FormHelperText>
                    </>
                  )}
                />
              </Box>
            }
          />
          <LabelField
            label="Access Token"
            value={
              <Box mt={2}>
                <Controller
                  control={control}
                  name="squareConfiguration.accessToken"
                  render={({ field }) => (
                    <InputField
                      {...field}
                      fullWidth
                      label="Access Token"
                      data-testid="squareAccessToken"
                      helperText={
                        !errors.squareConfiguration?.accessToken
                          ? ' '
                          : errors.squareConfiguration?.accessToken.message
                      }
                    />
                  )}
                />
              </Box>
            }
          />
          <LabelField
            label="Location Id"
            value={
              <Box mt={3}>
                <Controller
                  control={control}
                  name="squareConfiguration.locationId"
                  render={({ field }) => (
                    <InputField
                      {...field}
                      fullWidth
                      label="Location Id"
                      data-testid="squareLocationId"
                      helperText={
                        !errors.squareConfiguration?.locationId
                          ? ' '
                          : errors.squareConfiguration?.locationId.message
                      }
                    />
                  )}
                />
              </Box>
            }
          />
        </Stack>
      </form>
    </Stack>
  );
};
