import { useAuth } from '../useAuth';
import { useQuery } from '@tanstack/react-query';
import { ApiClient } from '../../api/apiClient';
import { DropdownType } from '@/types';
import { PurchasableType } from '@/types';

export const useCategoriesChoices = (purchasableType?: PurchasableType) => {
  const { data: user } = useAuth();
  const apiClient = ApiClient();
  const merchantId = user?.primaryMerchantId.toString();

  return useQuery<DropdownType[] | undefined>(
    ['categoriesChoices', merchantId, purchasableType],
    () => apiClient.productLookupItems.getCategoryChoices(purchasableType),
    {
      enabled: !!user?.id && !!user?.accessToken && !!merchantId,
    }
  );
};
