import {
  ListItem,
  IconButton,
  styled,
  ListItemButton,
} from '@silverstein-properties/inspirelabs-ui';

export const CloseButton = styled(IconButton)(() => ({
  position: 'absolute',
  left: 15,
  top: 15,
  padding: 5,
}));

export const StyledListItem = styled(ListItem)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.faint}`,
  borderRadius: theme.shape.borderRadius,
}));

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(2),
}));
