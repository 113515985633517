import { AxiosInstance } from 'axios';
import { MerchantUserRole, User } from '@/types';
import { CreatePasswordInput } from '@/classes';
import { MerchantDropdown } from '@/types/Merchant';

export const endpoints = {
  users: '/users',
  user({ userId }: { userId: string }) {
    return `/users/${userId}`;
  },
  resetPassword({ email }: { email: string }) {
    return `/users/${email}/password`;
  },
  isSuperAdmin: (userId: string) => `/users/${userId}/is-super-admin`,
  getAllAssociatedMerchants: (userId: string) => `/users/${userId}/merchants`,
  getUserRolesWithMerchant: (userId: string, merchantId: string) =>
    `/users/${userId}/roles-with-merchant/${merchantId}`,
};
export const users = ({ axiosInstance }: { axiosInstance: AxiosInstance }) => {
  return {
    getUserByEmail: async ({
      accessToken,
      email,
    }: {
      accessToken: string;
      email: string;
    }): Promise<User> => {
      const { data } = await axiosInstance.get(endpoints.users, {
        params: {
          email,
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return data;
    },
    updateUser: async ({
      updatedUser,
      userId,
    }: {
      updatedUser: Partial<User>;
      userId: string;
    }): Promise<User> => {
      const { data } = await axiosInstance.patch(
        endpoints.user({ userId }),
        updatedUser
      );
      return data;
    },
    resetPassword: async ({
      email,
      ...resetPasswordDto
    }: CreatePasswordInput): Promise<boolean> => {
      const { data } = await axiosInstance.put(
        endpoints.resetPassword({ email }),
        resetPasswordDto
      );
      return data;
    },
    isSuperAdmin: async ({ userId }: { userId: string }): Promise<boolean> => {
      const { data } = await axiosInstance.get(endpoints.isSuperAdmin(userId));
      return data;
    },
    getAllAssociatedMerchants: async ({
      userId,
    }: {
      userId: string;
    }): Promise<MerchantDropdown[]> => {
      const { data } = await axiosInstance.get(
        endpoints.getAllAssociatedMerchants(userId)
      );
      return data;
    },
    getUserRolesWithMerchant: async ({
      userId,
      merchantId,
    }: {
      userId: string;
      merchantId: string;
    }): Promise<MerchantUserRole[]> => {
      const { data } = await axiosInstance.get(
        endpoints.getUserRolesWithMerchant(userId, merchantId)
      );
      return data;
    },
  };
};
