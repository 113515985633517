import { ApiClient } from '@/api/apiClient';
import { TEXT_AREA_LIMIT_LONG } from '@/constants';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import {
  Grid,
  MessageBanner,
  Typography,
} from '@silverstein-properties/inspirelabs-ui';
import { IsNotEmpty, MaxLength } from 'class-validator';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { HookFormTextArea } from '../FormTextInput/HookFormTextArea';
import { MessageSuccess } from '../MessageSuccess/MessageSuccess';
import HookFormSubmitButton from '../RoundButton/HookFormSubmitButton';

export class SendMessageInput {
  @IsNotEmpty()
  @MaxLength(TEXT_AREA_LIMIT_LONG, {
    message: 'Please enter a shorter message.',
  })
  message: string;
}

//TODO -> refactor to make more modular as messaging context grows -> https://spinspire.monday.com/boards/3397008425/pulses/5530089440
export type MessagingDialogProps = {
  listingId?: string;
  bookingId?: string;
  firstName?: string;
  lastName?: string;
  membershipPlanPurchaseId?: string;
  consumerId?: string;
  sendMessage?: (data: { message: string }) => void;
  messageType: 'all' | 'one' | 'membershipOne' | 'membershipAll' | 'refund';
};

export const MessagingDialog = ({
  listingId,
  bookingId,
  firstName,
  lastName,
  messageType,
  membershipPlanPurchaseId,
  consumerId,
  sendMessage,
}: MessagingDialogProps) => {
  const [sendError, setSendError] = useState('');
  const [success, setSuccess] = useState(false);
  const resolver = classValidatorResolver(SendMessageInput);

  const {
    register,
    formState: { errors, dirtyFields },
    handleSubmit: useFormHandleSubmit,
  } = useForm<SendMessageInput>({
    resolver,
    mode: 'onTouched',
    values: { message: '' },
  });

  const apiClient = ApiClient();

  const handleSubmit = async (data: { message: string }) => {
    try {
      let sentMessage;
      if (messageType === 'all' && !!listingId) {
        sentMessage = await apiClient.products.messageAllBookedGuests({
          listingId: listingId,
          message: data.message,
        });
      } else if (messageType === 'one' && !!bookingId && !!listingId) {
        sentMessage = await apiClient.products.messageOneBookedGuest({
          listingId: listingId,
          consumerId: bookingId,
          message: data.message,
        });
      } else if (
        messageType === 'membershipOne' &&
        !!membershipPlanPurchaseId &&
        !!consumerId
      ) {
        sendMessage && sendMessage(data);
      } else {
        sentMessage = false;
      }
      // TODO: add failure message to let user know if message couldn't be sent
      //  https://spinspire.monday.com/boards/3396817916/pulses/5358317101
      if (sentMessage) {
        setSuccess(true);
      }
    } catch (err) {
      console.error(err);
      setSendError(err instanceof Error ? err.message : 'Unknown Error');
    }
  };

  return (
    <Grid sx={{ padding: '40px', gap: 1 }}>
      {success ? (
        <MessageSuccess message="Thank you. Your email was sent successfully." />
      ) : (
        <Grid flexDirection="column" sx={{ height: '269px' }}>
          {!!sendError && (
            <MessageBanner type="error" sx={{ width: '384px' }}>
              {sendError}
            </MessageBanner>
          )}
          <Typography variant="subtitle1">
            Email
            {messageType === 'all'
              ? ' all participants'
              : ` ${firstName} ${lastName}`}
          </Typography>
          <form onSubmit={useFormHandleSubmit(handleSubmit)}>
            <HookFormTextArea
              name="message"
              label={`Enter a message to your guest${
                messageType === 'all' ? 's' : ''
              }`}
              size="form-xl-messaging"
              limit={TEXT_AREA_LIMIT_LONG}
              register={register}
              isFilled={Object.keys(dirtyFields).includes('message')}
              error={errors.message}
            />
            <Grid
              sx={{
                position: 'absolute',
                right: '40px',
                bottom: '40px',
              }}
            >
              <HookFormSubmitButton
                text="Send"
                default="white-navy small"
                hover="blue-baby-blue small"
              />
            </Grid>
          </form>
        </Grid>
      )}
    </Grid>
  );
};
