import {
  MerchantUser,
  MerchantUserDisplayStatus,
  MerchantUserRole,
  MerchantUserStatus,
  User,
  UserRole,
} from '@/types';
import { Theme } from '@silverstein-properties/inspirelabs-ui/dist/src/theme/types/Theme';

export const validateRole = (user: User | undefined, role: UserRole) => {
  if (!user) {
    return false;
  }
  return user.roles?.some(r => r.role === role);
};

export const validateRoles = (
  user: User | undefined,
  rolesTobeValidated: UserRole[]
) => {
  if (!user) {
    return false;
  }
  return user.roles?.some(r => rolesTobeValidated.includes(r.role));
};

export const validateRoleByMerchantId = (
  user: User | undefined,
  role: UserRole,
  merchantId: string | undefined
) => {
  if (!user || !merchantId) {
    return false;
  }
  return user.roles?.some(r => r.role === role && r.merchantId === +merchantId);
};

export const validateRolesByMerchantId = (
  user: User | undefined | null,
  rolesTobeValidated: UserRole[],
  merchantId: string | undefined
) => {
  if (!user || !merchantId) {
    return false;
  }
  return user.roles?.some(
    r => rolesTobeValidated.includes(r.role) && r.merchantId === +merchantId
  );
};

export const getStatusDisplayName = (
  status: MerchantUserStatus,
  passwordResetTokenExpiry: Date | undefined
) => {
  if (
    status !== MerchantUserStatus.ACTIVE &&
    passwordResetTokenExpiry &&
    passwordResetTokenExpiry < new Date()
  ) {
    return MerchantUserDisplayStatus[MerchantUserStatus.EXPIRED];
  }
  return MerchantUserDisplayStatus[status];
};

export const getColorUserStatus = (teamMember: MerchantUser, theme: Theme) => {
  const StatusColor = {
    [MerchantUserStatus.ACTIVE]: theme.palette.success.main,
    [MerchantUserStatus.DEACTIVATED]: theme.palette.error.main,
    [MerchantUserStatus.DELETED]: theme.palette.error.main,
    [MerchantUserStatus.EXPIRED]: theme.palette.error.medium,
    [MerchantUserStatus.PENDING_INVITATION]: theme.palette.primary.medium,
    [MerchantUserStatus.PENDING_REGISTRATION]: theme.palette.primary.medium,
  };
  if (
    teamMember.passwordResetTokenExpiry &&
    teamMember.status !== MerchantUserStatus.ACTIVE &&
    teamMember.passwordResetTokenExpiry < new Date()
  ) {
    return theme.palette.error.main;
  }
  return StatusColor[teamMember.status];
};

/**
 * Returns the display label for the given user role
 * @param role - The role to get the display label for
 * @returns The text label for the given role
 */
export const getRoleLabel = (role: UserRole): string => {
  switch (role) {
    case UserRole.OWNER:
      return 'Owner';
    case UserRole.SP_INTERNAL:
      return 'Internal';
    case UserRole.ADMIN:
      return 'Admin';
    case UserRole.HOST:
      return 'Member';
    default:
      return role;
  }
};

/**
 * Filters out the INSPLB_ADMIN role from the list of roles
 * @param merchantUserRoles - The list of merchant-user-roles to filter
 * @returns The list of merchant-user-roles without the INSPLB_ADMIN role
 */
export const filterOutSuperAdminRole = (
  merchantUserRoles: MerchantUserRole[]
) => {
  return merchantUserRoles.filter(
    merchantUserRole => merchantUserRole.role !== UserRole.INSPLB_ADMIN
  );
};
