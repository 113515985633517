import { styled, TableRow, Table } from '@mui/material';

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderLeft: `1px solid ${theme.palette.grey[300]}`,
  borderRight: `1px solid ${theme.palette.grey[300]}`,
}));

// firefox show the scrollbar if the width is 100% event if the table is not overflowing
export const StyledTable = styled(Table)(() => ({
  width: 'calc(100% - 1px)',
}));
