/**
 *  This config object controls the visibility of small components/sections for different modules
 *  of the Hub app. Each visibility config can be easily "looked up" based on the merchant type.
 *
 *  For example, have a look at the "getExperienceDetailMenus" function in "src/utils/experienceUtils.ts".
 */
import { MerchantType } from '@/types/Merchant';

export const merchantVisibilityConfig = {
  [MerchantType.STANDARD]: {
    experiences: {
      createNewExperience: true,
    },
    experiencesDetails: {
      basicInfo: true,
      titleAndDescription: true,
      photos: true,
      location: true,
      availability: true,
      aboutTheHost: true,
      pricing: true,
      capacity: true,
      requirements: true,
      squareConnection: true,
      advertUrl: true,
      amenities: true,
      requestCommunication: true,
      cancelation: true,
      bookingRequests: true,
      bookingSchedule: true,
      submitForApproval: true,
    },
    // memberships: {
    //   // visibility config for memberships ...
    // },
    // schedule: {
    //   // visibility config for schedule ...
    // }
  },
  [MerchantType.AMENITIES]: {
    experiences: {
      createNewExperience: true,
    },
    experiencesDetails: {
      basicInfo: true,
      titleAndDescription: true,
      photos: true,
      location: true,
      availability: true,
      aboutTheHost: true,
      pricing: true,
      capacity: true,
      requirements: true,
      squareConnection: true,
      requestCommunication: true,
      advertUrl: true,
      amenities: true,
      cancelation: true,
      bookingRequests: true,
      bookingSchedule: true,
      submitForApproval: true,
    },
  },
  [MerchantType.HOME_SERVICES]: {
    experiences: {
      createNewExperience: false,
    },
    experiencesDetails: {
      basicInfo: true,
      titleAndDescription: true,
      photos: true,
      location: false,
      availability: true,
      aboutTheHost: false,
      pricing: true,
      capacity: false,
      requirements: false,
      squareConnection: false,
      advertUrl: true,
      amenities: true,
      requestCommunication: false,
      cancelation: false,
      bookingRequests: false,
      bookingSchedule: true,
      submitForApproval: true,
    },
  },
};
