//TODO: Change path of the file https://spinspire.monday.com/boards/3396817916/views/109076109/pulses/5086988917
import { Membership, MembershipPlan } from '@/types/MerchantMembership';
import { AxiosInstance } from 'axios';

export const endpoints = {
  memberships: '/memberships',
  getMerchantMemberships({ merchantId }: { merchantId: number }) {
    return `/memberships/merchant/${merchantId}`;
  },
  updateMembershipMembersCount({ merchantId }: { merchantId: number }) {
    return `/memberships/merchant/${merchantId}/active-members`;
  },
  deleteMembership({ membershipId }: { membershipId: string }) {
    return `/memberships/${membershipId}/delete`;
  },
  publishMemberships({ membershipId }: { membershipId: string }) {
    return `/memberships/${membershipId}/publish`;
  },
  membershipId({ membershipId }: { membershipId: string }) {
    return `/memberships/${membershipId}`;
  },
  exportMembershipReport({ membershipId }: { membershipId: string }) {
    return `/memberships/${membershipId}/membership-report`;
  },
};

export const memberships = ({
  axiosInstance,
}: {
  axiosInstance: AxiosInstance;
}) => {
  return {
    getMerchantMemberships: async ({
      merchantId,
    }: {
      merchantId: number;
    }): Promise<Membership[]> => {
      const { data } = await axiosInstance.get(
        endpoints.getMerchantMemberships({ merchantId })
      );
      return data;
    },
    updateMembershipCount: async ({
      merchantId,
    }: {
      merchantId: number;
    }): Promise<Membership[]> => {
      const { data } = await axiosInstance.put(
        endpoints.updateMembershipMembersCount({ merchantId })
      );
      return data;
    },
    createMembership: async ({
      merchantId,
      name,
      description,
      agreementNb,
    }: {
      merchantId: number;
      name: string;
      description: string;
      agreementNb?: string;
    }): Promise<Membership> => {
      const { data } = await axiosInstance.post(endpoints.memberships, {
        merchantId,
        name,
        description,
        agreementNb,
      });
      return data;
    },
    getMembership: async ({
      membershipId,
      withProducts = false,
    }: {
      membershipId: string;
      withProducts?: boolean;
    }): Promise<Membership> => {
      const { data } = await axiosInstance.get(
        endpoints.membershipId({ membershipId }),
        { params: { withProducts } }
      );
      return data;
    },
    deleteMembership: async ({
      membershipId,
    }: {
      membershipId: string;
    }): Promise<Membership> => {
      const { data } = await axiosInstance.put(
        endpoints.deleteMembership({ membershipId })
      );
      return data;
    },
    updateMembership: async ({
      membershipId,
      merchantId,
      name,
      description,
      coverPhoto,
      photos,
    }: {
      membershipId: string;
      merchantId: number;
      name?: string;
      description?: string;
      coverPhoto?: string;
      photos?: {
        photo1?: string;
        photo2?: string;
        photo3?: string;
        photo4?: string;
      };
    }): Promise<Membership> => {
      const { data } = await axiosInstance.put(
        endpoints.membershipId({ membershipId }),
        {
          name,
          description,
          coverPhoto,
          photos,
        },
        {
          params: {
            merchantId,
          },
        }
      );
      return data;
    },
    publishMembershipPlans: async ({
      membershipId,
    }: {
      membershipId: string;
    }): Promise<MembershipPlan[]> => {
      const { data } = await axiosInstance.put(
        endpoints.publishMemberships({ membershipId })
      );
      return data;
    },
    exportMembershipReport: async ({
      membershipId,
    }: {
      membershipId: string;
    }): Promise<Blob> => {
      const { data } = await axiosInstance.get(
        endpoints.exportMembershipReport({ membershipId }),
        { responseType: 'blob' }
      );
      return data;
    },
  };
};
