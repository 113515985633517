import { Card, CardContent } from '@mui/material';
import { styled } from '@silverstein-properties/inspirelabs-ui';

export type StyledCardProps = {
  isSelected: boolean;
};

export const StyledCard = styled(Card, {
  shouldForwardProp: prop => prop !== 'isSelected',
})<StyledCardProps>(({ theme, isSelected }) => ({
  cursor: 'pointer',
  outline: `1px solid ${
    isSelected ? theme.palette.primary.main : 'transparent'
  }`,
  border: `1px solid ${
    isSelected ? theme.palette.primary.main : theme.palette.grey[400]
  }`,
  '&:hover': {
    border: `1px solid ${theme.palette.primary.main}`,
    outline: `1px solid ${theme.palette.primary.main}`,
  },
}));

export const StyledCardContent = styled(CardContent)(({ theme }) => ({
  '&:last-child': {
    paddingBottom: theme.spacing(2),
  },
  '& > img': {
    paddingRight: theme.spacing(2),
  },
}));
