import { RefundInput } from '@/classes/RefundInput';
import { HookFormTextArea } from '@/components/FormTextInput/HookFormTextArea';
import { MessageSuccess } from '@/components/MessageSuccess/MessageSuccess';
import { REFUND_REASONS, TEXT_AREA_LIMIT_LONG } from '@/constants';
import { useRefundBookingMutation } from '@/hooks';
import { RefundReasons } from '@/types';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Grid,
  InputField,
  Typography,
} from '@silverstein-properties/inspirelabs-ui';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  StyledDialogActions,
  RefundDialogContainer,
  StyledDialogTitle,
} from './RefundDialog.styles';
import { roundToTwoDecimalPlaces } from '@/utils/commonUtils';

export interface RefundDialogProps {
  bookingId: string;
  amount: number;
  firstName: string;
  lastName: string;
  onClose: () => void;
  onSuccess?: () => void;
}

const resolver = classValidatorResolver(RefundInput);

export const RefundDialog: React.FC<RefundDialogProps> = ({
  bookingId,
  amount,
  firstName,
  lastName,
  onClose,
  onSuccess,
}) => {
  const [sendError, setSendError] = useState('');
  const [success, setSuccess] = useState(false);

  const {
    control,
    register,
    formState: { errors, dirtyFields },
    handleSubmit,
  } = useForm<RefundInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      amount: roundToTwoDecimalPlaces(amount),
      maxAmount: roundToTwoDecimalPlaces(amount),
      reason: '',
      notes: '',
    },
  });

  const { mutate: refundBooking, isLoading } = useRefundBookingMutation(
    () => {
      setSuccess(true);
      onSuccess?.();
    },
    err => {
      console.error(err);
      setSendError(err instanceof Error ? err.message : 'Unknown Error');
    }
  );

  const onSubmit = async (data: RefundInput) => {
    refundBooking({
      bookingId,
      refundBookingDto: {
        amount: Number(data.amount),
        reason: data.reason || RefundReasons.requested_by_customer,
        notes: data.notes,
      },
    });
  };

  return (
    <RefundDialogContainer container>
      {success ? (
        <MessageSuccess message="Refund processed successfully" />
      ) : (
        <Grid container direction="column" flexGrow={1}>
          <StyledDialogTitle variant="h6" id="refund-dialog-title">
            Refund {firstName} {lastName}
          </StyledDialogTitle>
          {!!sendError && (
            <Typography id="refund-dialog-error" variant="body2" color="error">
              {sendError}
            </Typography>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box className="form-xl">
              <Controller
                control={control}
                name="amount"
                render={({ field }) => (
                  <InputField
                    {...field}
                    data-testid="refundAmountInput"
                    label="Refund Amount"
                    type="number"
                    isPriceInput
                    fullWidth
                    error={!!errors.amount}
                    helperText={
                      errors.amount?.message ||
                      'Enter any amount you’d like to refund, \n up to a maximum of the original transaction value'
                    }
                    aria-labelledby="refund-dialog-title"
                    aria-describedby="refund-dialog-error"
                  />
                )}
              />
            </Box>
            <Controller
              control={control}
              name="reason"
              render={({ field }) => (
                <InputField
                  {...field}
                  data-testid="refundReasonInput"
                  label="Reason"
                  select
                  dropdownItems={REFUND_REASONS}
                  error={!!errors.reason}
                  helperText={errors.reason?.message}
                  aria-labelledby="refund-dialog-title"
                  aria-describedby="refund-dialog-error"
                />
              )}
            />
            <HookFormTextArea
              labelStyle={{ textAlign: 'left' }}
              size="form-xl"
              name="notes"
              label="(optional) Refund notes"
              limit={TEXT_AREA_LIMIT_LONG}
              register={register}
              isFilled={Object.keys(dirtyFields).includes('notes')}
              error={errors.notes}
              aria-labelledby="refund-dialog-title"
              aria-describedby="refund-dialog-error"
            />
            <StyledDialogActions
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Button variant="text" color="primary" onClick={onClose}>
                Cancel
              </Button>

              <LoadingButton
                type="submit"
                loading={isLoading}
                size="small"
                variant="outlined"
              >
                Process Refund
              </LoadingButton>
            </StyledDialogActions>
          </form>
        </Grid>
      )}
    </RefundDialogContainer>
  );
};
