import { AxiosInstance } from 'axios';
import { DropdownType, PurchasableType } from '@/types';

export const endpoints = {
  categoryChoices() {
    return '/product-lookup-items/service-categories';
  },
  subCategoryChoices() {
    return '/product-lookup-items/service-subcategories';
  },
};

export const productLookupItems = ({
  axiosInstance,
}: {
  axiosInstance: AxiosInstance;
}) => {
  return {
    getCategoryChoices: async (
      purchasableType?: PurchasableType
    ): Promise<DropdownType[]> => {
      const { data } = await axiosInstance.get<DropdownType[]>(
        endpoints.categoryChoices(),
        {
          params: purchasableType ? { purchasableType } : undefined,
        }
      );
      return data;
    },
    getSubCategoryChoices: async (
      categoryIds?: string
    ): Promise<DropdownType[]> => {
      const { data } = await axiosInstance.get<DropdownType[]>(
        endpoints.subCategoryChoices(),
        {
          params: categoryIds ? { categoryIds } : undefined,
        }
      );
      return data;
    },
  };
};
