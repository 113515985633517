import { TEXT_AREA_LIMIT_LONG, TEXT_AREA_LIMIT_SHORT } from '@/constants';
import { ProductTypes } from '@/types';
import { Transform, Type } from 'class-transformer';
import {
  IsNotEmpty,
  IsNumber,
  IsOptional,
  MaxLength,
  ValidateIf,
  ValidateNested,
} from 'class-validator';

export class DurationDto {
  @IsNotEmpty({ message: 'Please enter a value' })
  @Transform(({ value }) => parseInt(value))
  @IsNumber({}, { message: 'Please enter a number' })
  hours: number | string;

  @IsNotEmpty({ message: 'Please enter a value' })
  @Transform(({ value }) => parseInt(value))
  @IsNumber({}, { message: 'Please enter a number' })
  minutes: number | string;
}

export class TitleAndDescriptionSectionEditInput {
  @MaxLength(TEXT_AREA_LIMIT_SHORT, { message: 'Please enter a shorter title' })
  @IsNotEmpty({ message: 'Please enter a title' })
  title: string;

  @MaxLength(TEXT_AREA_LIMIT_SHORT, {
    message: 'Please enter a shorter internal name',
  })
  @IsOptional()
  internalName: string;

  @MaxLength(TEXT_AREA_LIMIT_SHORT, {
    message: 'Please enter a shorter subtitle',
  })
  @IsOptional()
  subtitle: string;

  @MaxLength(TEXT_AREA_LIMIT_LONG, {
    message: 'Please enter a shorter description',
  })
  @IsNotEmpty({ message: 'Please enter a description' })
  description: string;

  @ValidateIf(
    o =>
      !o.productType &&
      [ProductTypes.ADVERT, ProductTypes.SPACE].includes(o.productType)
  )
  @ValidateNested()
  @Type(() => DurationDto)
  duration: DurationDto;

  productType?: ProductTypes;
}
