import { useMutation, useQuery } from '@tanstack/react-query';
import { ApiClient } from '../api/apiClient';
import {
  BookingRequest,
  BookingRequestLoaded,
  CancellationPolicy,
  CreateListing,
  Experience,
  Listing,
  Location,
  PriceType,
} from '@/types';
import { AxiosError } from 'axios';
import {
  doesDateMatchDateTime,
  isAddressDifferent,
  parseFloatIfString,
  replaceDateInDateTime,
} from '@/utils';
import { useLocations } from './useLocations';
import { AddQuoteInput } from '@/classes';
import { validateListing } from './useListings';

export const useBookingRequest = (
  bookingRequestId: string,
  shouldCallApi = true
) => {
  const apiClient = ApiClient();

  const getBookingRequestById = async (): Promise<BookingRequest> => {
    return (await apiClient.bookingRequests.getBookingRequestById({
      bookingRequestId,
      withEntities: false,
    })) as BookingRequest;
  };

  return useQuery<BookingRequest>(
    ['bookingRequest', bookingRequestId],
    () => getBookingRequestById(),
    { enabled: shouldCallApi }
  );
};

export const useBookingRequestLoaded = (
  bookingRequestId: string,
  shouldCallApi = true
) => {
  const apiClient = ApiClient();

  const getBookingRequestLoadedById =
    async (): Promise<BookingRequestLoaded> => {
      return (await apiClient.bookingRequests.getBookingRequestById({
        bookingRequestId,
        withEntities: true,
      })) as BookingRequestLoaded;
    };

  return useQuery<BookingRequestLoaded>(
    ['bookingRequestLoaded', bookingRequestId],
    () => getBookingRequestLoadedById(),
    { enabled: shouldCallApi }
  );
};

export const useQuoteMutation = (
  data: {
    bookingRequestId: string;
    consumerId: string;
    experience?: Experience;
    listing?: Listing;
  },
  onMutationSuccess: () => void,
  onMutationError: (message: string, error?: Error) => void
) => {
  const { bookingRequestId, experience } = data;
  const { data: venue } = useLocations({
    locationId: experience?.location?.id?.toString() || '',
  });
  const apiClient = ApiClient();
  const addQuoteMutation = useMutation({
    mutationFn: async (data: AddQuoteInput) => {
      const productId = experience?.id || '';
      validateListing({ experience, listingChanges: data });

      try {
        const address = {
          city: data.city,
          country: venue?.address?.country || 'USA',
          state: data.state,
          street: data.address,
          zipCode: data.zipCode,
          apartment: data.apt,
        };

        let meetingPoint;

        // create new meeting point location
        if (isAddressDifferent(address, venue?.address)) {
          const newLocation: Location = {
            address,
            info: data.description,
            description: data.description,
            environmentType: venue?.environmentType,
            name: venue?.name,
            type: venue?.type,
            url: venue?.url,
          };

          meetingPoint = await apiClient.locations.createLocation({
            newLocation,
          });
        }

        if (!doesDateMatchDateTime(data.startTime, data.date)) {
          data.startTime = replaceDateInDateTime(data.startTime, data.date);
          data.endTime = replaceDateInDateTime(data.endTime, data.date);
        }

        const listingDto: Partial<CreateListing> = {
          endsAt: data.endTime,
          startsAt: data.startTime,
          title: experience?.title,
          basePrice: {
            priceId: data.basePrice.priceId,
            amount: parseFloatIfString(data.basePrice.amount),
            taxRateId: parseFloatIfString(data.basePrice.taxRateId || ''),
            priceType:
              data.basePrice.priceType ||
              experience?.basePrice.priceType ||
              PriceType.PRICE_PER_PERSON,
            description: data.basePrice.description,
            membershipPlanId: data.basePrice.membershipPlanId,
          },
          productId,
          meetingPointId: meetingPoint?.id,
          meetingPoint,
          host: experience?.host,
          description: data.description,
          cancellationPolicy:
            data.cancellationPolicy || CancellationPolicy.FULL_1W_PRIOR,
          capacity: { min: 0, max: parseFloatIfString(data.numberOfGuests) },
        };

        return await apiClient.bookingRequests.addBookingRequestListing({
          bookingRequestId,
          newListing: listingDto,
        });
      } catch (error) {
        console.error(error);
        throw new Error('There was an error creating this quote');
      }
    },
    onSuccess() {
      onMutationSuccess();
    },
    onError(error: AxiosError<{ message: string; statusCode: number }>) {
      const errorMessage = !!error.message
        ? error.message
        : error.response?.data.message;
      onMutationError(
        errorMessage || 'There was an error creating this quote',
        error
      );
    },
  });

  // TODO: add editQuoteMutation for editing quotes
  return {
    addQuoteMutation,
  };
};

export const retractQuoteMutation = (
  data: {
    bookingRequestId: string;
  },
  onMutationSuccess: () => void,
  onMutationError: (message: string, error?: Error) => void
) => {
  const { bookingRequestId } = data;
  const apiClient = ApiClient();

  return useMutation({
    mutationFn: async () => {
      try {
        return await apiClient.bookingRequests.retractBookingRequestListing({
          bookingRequestId,
        });
      } catch (error) {
        console.error(error);
        throw new Error('There was an error retracting this quote');
      }
    },
    onSuccess() {
      onMutationSuccess();
    },
    onError(error: AxiosError<{ message: string; statusCode: number }>) {
      const errorMessage = !!error.message
        ? error.message
        : error.response?.data.message;
      onMutationError(
        errorMessage || 'There was an error retracting this quote',
        error
      );
    },
  });
};

export const deleteQuoteMutation = (
  data: {
    bookingRequestId: string;
  },
  onMutationSuccess: () => void,
  onMutationError: (message: string, error?: Error) => void
) => {
  const { bookingRequestId } = data;

  const apiClient = ApiClient();

  return useMutation({
    mutationFn: async () => {
      try {
        return await apiClient.bookingRequests.deleteBookingRequestListing({
          bookingRequestId,
        });
      } catch (error) {
        console.error(error);
        throw new Error('There was an error deleting this quote');
      }
    },
    onSuccess() {
      onMutationSuccess();
    },
    onError(error: AxiosError<{ message: string; statusCode: number }>) {
      const errorMessage = !!error.message
        ? error.message
        : error.response?.data.message;
      onMutationError(
        errorMessage || 'There was an error deleting this quote',
        error
      );
    },
  });
};

export const useBookingRequestMembershipPlanPurchase = (
  membershipPlanPurchaseId: string
) => {
  const apiClient = ApiClient();

  const getMembershipPlanPurchase = async () => {
    const bookingRequests =
      await apiClient.bookingRequests.getBookingRequestMembershipPlanPurchase({
        membershipPlanPurchaseId,
      });
    if (bookingRequests.length > 0) {
      return bookingRequests[0];
    }
    return;
  };

  return useQuery<BookingRequestLoaded | undefined>(
    ['bookingRequestByMembershipPlanPurchaseId', membershipPlanPurchaseId],
    () => getMembershipPlanPurchase(),
    { enabled: !!membershipPlanPurchaseId }
  );
};
