import { Amenity } from './Amenity';
import { ProductAvailability } from './Availability';
import { BookingMethod } from './Booking';
import { Address, LocationEnvironmentTypes } from './Location';
import { Price } from './Price';
import { ProductTypes } from './Product';
import { SquareConfiguration } from './Square';
import { RecursiveKeyOf } from './Util';

export interface ExperienceCategory {
  type?: string[];
  info?: string[];
}

export interface ExperiencePhotos {
  photo1?: string;
  photo2?: string;
  photo3?: string;
  photo4?: string;
}

export interface ExperienceDuration {
  hours?: number;
  minutes?: number;
}

export interface ExperienceLocation {
  id?: number;
  name: string;
  info?: string;
  zipCode?: string;
  address?: string | Address;
  environmentType?: LocationEnvironmentTypes;
  type?: number;
  url: string;
}

export enum PurchaseMethods {
  ONE_TIME_MONEY_PURCHASE = 'one-time-money-purchase',
  SUBSCRIPTION = 'subscription',
  FIDELITY_POINTS = 'fidelity-points',
  VOUCHER = 'voucher',
}

export interface ExperienceHost {
  operatingYears: string;
  bio: string;
  phoneNumber: string;
  hostPhoto: string;
  firstName?: string;
  lastName?: string;
}

export interface ExperienceRequirements {
  byHost?: string;
  byGuest?: string;
}

export interface ExperienceAgreement {
  type?: string;
  status?: string;
}

export interface ExperienceCapacity {
  min?: number;
  max?: number;
}

export enum ExperienceStatus {
  'In Progress',
  'Pending Approval',
  'Rejected',
  'Approved',
  'Published',
  'Suspended',
  'Hidden',
}

export enum CancellationPolicy {
  CUSTOM = 'custom',
  FULL_24H_PRIOR = 'full-24h-prior',
  FULL_48H_PRIOR = 'full-48h-prior',
  FULL_3D_PRIOR = 'full-3d-prior',
  FULL_5D_PRIOR = 'full-5d-prior',
  FULL_1W_PRIOR = 'full-1w-prior',
  FULL_ANYTIME = 'full-anytime',
}

export interface RefundPolicy {
  cancellationPolicy: CancellationPolicy; // reference to the Cancellation Policy (same, but in plain English)
  referenceDate?: Date; // ex.: default to the start of the Event (for Experiences) or to the purchase date (for physical products)
  thresholdInHours: number; // ex.: -24 would be 24h before reference date. Like 24h before the start of an Event
  beforeThresholdPriceRefundPercentage: number; // ex.: 50%, with -24 and referenceDate of 2023-02-01T12:00 would mean 50% back before 2023-02-01T12:00 -24h => 2023-01-31T12:00
  afterThresholdPriceRefundPercentage: number; // same as above, but after threshold
  beforeThresholdFeeRefundPercentage: number; // ex.: 50%, with -24 and referenceDate of 2023-02-01T12:00 would mean 50% back before 2023-02-01T12:00 -24h => 2023-01-31T12:00
  afterThresholdFeeRefundPercentage: number; // same as above, but after threshold
}

export interface BookingRequestInfo {
  info: string;
}

export interface Experience {
  id: string;
  type: ProductTypes;
  title: string;
  subtitle?: string;
  internalName?: string;
  description?: string;
  category?: ExperienceCategory;
  targetAudience?: string;
  coverPhoto?: string;
  photos: ExperiencePhotos;
  price?: number;
  totalAmount?: number;
  refundPolicy?: RefundPolicy;
  duration?: ExperienceDuration;
  inPerson: boolean;
  listings: [];
  location?: ExperienceLocation;
  locationId?: number;
  buildingIds?: { [key: string]: string } | string[]; // may need refactoring to ensure consistensy
  host?: ExperienceHost;
  activityLevel?: string;
  requiredSupply?: ExperienceRequirements;
  agreement?: ExperienceAgreement;
  capacity?: ExperienceCapacity;
  groupBookingsAllowed?: boolean;
  acceptedPurchaseMethods: PurchaseMethods[];
  bookingRequestInfo?: BookingRequestInfo;
  taxRateId?: number;
  merchantId?: number;
  status: ExperienceStatus;
  basePrice: Price;
  bookingMethods?: BookingMethod[];
  squareConfiguration?: SquareConfiguration;
  advertUrl?: string;
  amenities?: Amenity[];
  availability?: ProductAvailability;

  approvedAt?: Date;
  submittedAt?: Date;

  createdUserId: number;
  createdAt: string;
  updatedUserId?: number;
  updatedAt?: string;
  deletedUserId?: number;
  deletedAt?: string;
}

export type CustomExperienceCheck = {
  key: RecursiveKeyOf<Experience>;
  value?: unknown;
  completeInfoCheck: Record<
    string | number | symbol,
    RecursiveKeyOf<Experience>[]
  >;
};
