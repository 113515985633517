import { AxiosInstance } from 'axios';
import {
  BookingRequest,
  BookingRequestLoaded,
  BookingRequestStatus,
  CreateListing,
  Listing,
  Pagination,
  PaginationQuery,
} from '@/types';

export const endpoints = {
  bookingRequests() {
    return '/booking-requests';
  },
  allBookingRequests({ productId }: { productId: string }) {
    return `/booking-requests/products/${productId}`;
  },
  bookingRequestsById({ bookingRequestId }: { bookingRequestId: string }) {
    return `/booking-requests/${bookingRequestId}`;
  },
  addBookingRequestListing({ bookingRequestId }: { bookingRequestId: string }) {
    return `/booking-requests/${bookingRequestId}/listings`;
  },
  retractBookingRequestListing({
    bookingRequestId,
  }: {
    bookingRequestId: string;
  }) {
    return `/booking-requests/${bookingRequestId}/listings/retract`;
  },
  deleteBookingRequestListing({
    bookingRequestId,
  }: {
    bookingRequestId: string;
  }) {
    return `/booking-requests/${bookingRequestId}/listings/delete`;
  },
};

export const bookingRequests = ({
  axiosInstance,
}: {
  axiosInstance: AxiosInstance;
}) => {
  return {
    getAllBookingRequests: async ({
      productId,
      status,
      search,
      pagination,
    }: {
      productId: string;
      search?: string;
      status?: BookingRequestStatus[];
      pagination: PaginationQuery;
    }): Promise<Pagination<BookingRequestLoaded>> => {
      const { data } = await axiosInstance.get(
        endpoints.allBookingRequests({ productId }),
        {
          params: { status, search, ...pagination },
        }
      );
      return data;
    },
    getBookingRequestById: async ({
      bookingRequestId,
      withEntities = false,
    }: {
      bookingRequestId: string;
      withEntities?: boolean;
    }): Promise<BookingRequest | BookingRequestLoaded> => {
      const { data } = await axiosInstance.get(
        endpoints.bookingRequestsById({ bookingRequestId }),
        { params: { withEntities } }
      );
      return data;
    },
    addBookingRequestListing: async ({
      bookingRequestId,
      newListing,
    }: {
      bookingRequestId: string;
      newListing: Partial<CreateListing>;
    }): Promise<Listing> => {
      const { data } = await axiosInstance.post(
        endpoints.addBookingRequestListing({ bookingRequestId }),
        newListing
      );
      return data;
    },
    retractBookingRequestListing: async ({
      bookingRequestId,
    }: {
      bookingRequestId: string;
    }): Promise<Listing> => {
      const { data } = await axiosInstance.put(
        endpoints.retractBookingRequestListing({ bookingRequestId })
      );
      return data;
    },
    deleteBookingRequestListing: async ({
      bookingRequestId,
    }: {
      bookingRequestId: string;
    }): Promise<void> => {
      const { data } = await axiosInstance.put(
        endpoints.deleteBookingRequestListing({ bookingRequestId })
      );
      return data;
    },
    getBookingRequestMembershipPlanPurchase: async ({
      membershipPlanPurchaseId,
    }: {
      membershipPlanPurchaseId: string;
    }): Promise<BookingRequestLoaded[]> => {
      const { data } = await axiosInstance.get(endpoints.bookingRequests(), {
        params: { membershipPlanPurchaseId },
      });
      return data;
    },
  };
};
