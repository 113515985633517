import {
  CycleType,
  BillingCycleTypeDisplay,
  MembershipPlanPurchase,
  PaymentStatus,
  PlanPurchaseStatus,
  MembershipPlan,
} from '@/types';
import {
  MEMBERSHIP_DISPLAY_STATUS,
  MembershipDisplayStatusObject,
} from '@/constants/membershipConstants';
import { PlanConsumerStatus } from '@/types';
import { ContractType } from '@/types/MerchantMembership';

const planConsumerStatusMap: Record<string, string> = {
  requires_agreement_acceptance: 'Requires agreement acceptance',
  active: 'Active', // signifies that the user is part of the plan
  inactive: 'Inactive',
  placeholder: 'Placeholder',
};

// TODO: memberQuantityMap and billingCycleMap should come from the BE
// https://spinspire.monday.com/boards/3397008425/pulses/5156512429
export const memberQuantityMap = [
  {
    display: '1 member',
    label: '1 member',
    value: 1,
  },
  {
    display: '2 members',
    label: '2 members',
    value: 2,
  },
  {
    display: '3 members',
    label: '3 members',
    value: 3,
  },
  {
    display: '4 members',
    label: '4 members',
    value: 4,
  },
];

export const contractTypeMap = [
  {
    label: 'One time contract',
    value: ContractType.ONE_TIME,
  },
  {
    label: 'Auto-renew',
    value: ContractType.AUTO_RENEW,
  },
];

export const contractLengthMap = {
  '1 week': {
    length: 1,
    type: CycleType.WEEK,
  },
  '1 month': {
    length: 1,
    type: CycleType.MONTH,
  },
  '3 months': {
    length: 3,
    type: CycleType.MONTH,
  },
  '6 months': {
    length: 6,
    type: CycleType.MONTH,
  },
  '1 year': {
    length: 1,
    type: CycleType.YEAR,
  },
};

export const billingCycleMap = {
  'Every 1 week': {
    length: 1,
    type: CycleType.WEEK,
  },
  'Every 1 month': {
    length: 1,
    type: CycleType.MONTH,
  },
  'Every 3 months': {
    length: 3,
    type: CycleType.MONTH,
  },
  'Every 6 months': {
    length: 6,
    type: CycleType.MONTH,
  },
  'Every 1 year': {
    length: 1,
    type: CycleType.YEAR,
  },
};

/**
 * Convert a cycle to a number of months (e.g. 1 year = 12 months, 1 week = 0.25 month) so that we can compare the valid selection
 * @param length Billing cycle length/contract length
 * @param type CycleType.MONTH, CycleType.YEAR, ...
 * @returns total number of months
 */
export const convertToMonths = (length: number, type: CycleType): number => {
  if (type === CycleType.YEAR) {
    return length * 12;
  } else if (type === CycleType.WEEK) {
    return length / 4; // ---> 4 weeks = 1 month
  }
  return length; // CycleType.MONTH
};

export const convertPriceAndBillingToString = (
  price: number,
  billing: CycleType
): string => {
  return `$${price}/${BillingCycleTypeDisplay[billing]}`;
};

/**
 * Render contract length in words
 * @param membershipPlan MembershipPlan
 * @returns 'Monthly contract', '6 months contract', 'Annual contract', '2 years contract', etc.
 *
 * NOTE: Do it this way b/c we previous membership plans don't have a 'contractType' & 'contractLength'
 */
export const renderContractLength = (membershipPlan: MembershipPlan) => {
  const contractType = !!membershipPlan?.contractType
    ? membershipPlan.contractType
    : membershipPlan.billingCycleType;
  const contractLength = !!membershipPlan?.contractLength
    ? membershipPlan.contractLength
    : membershipPlan.billingCycleLength;

  if (contractType.includes('week')) {
    return contractLength === 1
      ? 'Weekly contract'
      : `${contractLength} weeks contract`;
  } else if (contractType.includes('month')) {
    return contractLength === 1
      ? 'Monthly contract'
      : `${contractLength} months contract`;
  } else if (contractType.includes('year')) {
    return contractLength === 1
      ? 'Annual contract'
      : `${contractLength} years contract`;
  }
  return '';
};

/**
 *
 * @param billingCycleLength number
 * @param billingCycleType BillingCycleType
 * @returns a string matching a key from billingCycleMap
 */
export const determineBillingCycleFromPlan = (
  billingCycleLength: number,
  billingCycleType: CycleType
): string => {
  return Object.keys(billingCycleMap).reduce((acc, billingCycleName) => {
    const billingCycle =
      billingCycleMap[billingCycleName as keyof typeof billingCycleMap];
    if (
      billingCycle.length === billingCycleLength &&
      billingCycle.type === billingCycleType
    ) {
      acc = billingCycleName;
    }
    return acc;
  }, '');
};

export const determineMembersDisplayStatus = (
  planPurchase: MembershipPlanPurchase
): MembershipDisplayStatusObject => {
  const stillValid = planPurchase.validUntil > new Date();

  if (planPurchase.status === PlanPurchaseStatus.ACTIVE) {
    return MEMBERSHIP_DISPLAY_STATUS.ACTIVE;
  } else if (planPurchase.status === PlanPurchaseStatus.CANCELLED_BY_CONSUMER) {
    return stillValid
      ? MEMBERSHIP_DISPLAY_STATUS.ACTIVE_WITH_EXPIRATION(
          planPurchase.validUntil
        )
      : MEMBERSHIP_DISPLAY_STATUS.CANCELLED;
  } else if (planPurchase.status === PlanPurchaseStatus.CANCELLED_BY_MERCHANT) {
    return stillValid
      ? MEMBERSHIP_DISPLAY_STATUS.ACTIVE_WITH_EXPIRATION(
          planPurchase.validUntil
        )
      : MEMBERSHIP_DISPLAY_STATUS.TERMINATED;
  } else if (planPurchase.status === PlanPurchaseStatus.PAST_DUE) {
    return MEMBERSHIP_DISPLAY_STATUS.PAST_DUE;
  } else if (planPurchase.status === PlanPurchaseStatus.TRIALING) {
    return MEMBERSHIP_DISPLAY_STATUS.TRIALING(planPurchase.validUntil);
  }

  if (planPurchase.paymentStatus === PaymentStatus.FAILED) {
    return MEMBERSHIP_DISPLAY_STATUS.PAST_DUE;
  }

  return MEMBERSHIP_DISPLAY_STATUS.DEFAULT;
};

/**
 * formats PlanConsumerStatus into a properly capitalized string
 * @param status PlanConsumerStatus|string
 * @returns string
 */
export const formatPlanConsumerStatus = (
  status?: PlanConsumerStatus | string
): string => {
  if (status === undefined) {
    return 'Status not available';
  }
  if (typeof status === 'string') {
    return planConsumerStatusMap[status] || status;
  }
  return planConsumerStatusMap[PlanConsumerStatus[status]];
};
