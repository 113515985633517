import { TEXT_AREA_LIMIT_SHORT } from '@/constants';
import {
  IsNotEmpty,
  MaxLength,
  ArrayNotEmpty,
  IsCurrency,
  IsOptional,
} from 'class-validator';
import type { ContractType, Experience } from '@/types';
import { billingCycleMap, contractLengthMap } from '@/utils/membershipUtils';
import { PriceMinValue } from './validations/PriceMinValue';

export class TitleAndDescriptionSectionEditInput {
  @MaxLength(TEXT_AREA_LIMIT_SHORT, { message: 'Please enter a shorter title' })
  @IsNotEmpty({ message: 'Please enter a title' })
  name: string;

  @IsNotEmpty({ message: 'Please enter a description' })
  description: string;
}

export class PlanEditInput {
  @MaxLength(TEXT_AREA_LIMIT_SHORT, { message: 'Please enter a shorter title' })
  @IsNotEmpty({ message: 'Please enter a title' })
  membershipPlanTitle: string;

  @IsCurrency(
    { allow_decimal: true, digits_after_decimal: [0, 1, 2] },
    { message: 'Please enter a valid price' }
  )
  @IsNotEmpty({ message: 'Please enter a price' })
  @PriceMinValue(0, { message: 'Please enter a valid price' })
  membershipPlanPrice: string;
  // TODO: should be a number but inputField is not cooperating

  @IsNotEmpty({ message: 'Please enter quantity' })
  memberQuantity: number;

  @IsNotEmpty({ message: 'Please select a contract type' })
  contractType: ContractType;

  @IsNotEmpty({ message: 'Please select a contract length' })
  contractLength: keyof typeof contractLengthMap;

  @IsNotEmpty({ message: 'Please select a billing cycle' })
  billingCycle: keyof typeof billingCycleMap;

  @ArrayNotEmpty({ message: 'Please select some products' })
  membershipProducts: Experience[];

  @IsOptional()
  taxRateId?: number;
}

// TODO: have this match creation form where you can also update memberships
