import { useQuery } from '@tanstack/react-query';
import { ApiClient } from '@/api/apiClient';
import { useAuth } from './useAuth';

export const useAssociatedMerchants = () => {
  const { data: user } = useAuth();
  const apiClient = ApiClient();

  return useQuery({
    queryKey: ['merchantsForSuperAdmin'],
    queryFn: () =>
      apiClient.users.getAllAssociatedMerchants({
        userId: user?.id.toString() || '',
      }),
    enabled: !!user && !!user.id,
  });
};
