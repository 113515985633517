import {
  defaultSubscriptionCreationObject,
  useFormData,
} from '@/contexts/MembershipCreationProvider';
import { ApiClient } from '../api/apiClient';
import { useState } from 'react';
import { Membership } from '@/types/MerchantMembership';
import { useAuth } from './useAuth';
import { Experience } from '@/types';
import {
  MembershipPlanInput,
  TitleAndDescriptionInput,
} from '@/classes/MembershipCreationInput';
import { billingCycleMap, contractLengthMap } from '@/utils/membershipUtils';
import { useMerchant } from './useMerchant';
import { ContractType } from '@/types/MerchantMembership';

export enum MenuProgressStatus {
  Locked = 1,
  InProgress,
  Finished,
}

export enum MembershipCreationDirection {
  Forward = 1,
  Backwards = -1,
}

export enum MembershipCreationSection {
  Details = 0,
  Plans,
  Publish,
  Success,
}

export interface MembershipCategorySubItem {
  subItemId: number;
  name: string;
  finished: boolean;
}

export interface MembershipCategory {
  title: string;
  subItems: MembershipCategorySubItem[];
  sectionId: number;
}

export const defaultCategories: MembershipCategory[] = [
  {
    title: 'Membership details',
    subItems: [{ subItemId: 0, name: 'Title & description', finished: false }],
    sectionId: MembershipCreationSection.Details,
  },
  {
    title: 'Plans',
    subItems: [{ subItemId: 0, name: 'Experience', finished: false }],
    sectionId: MembershipCreationSection.Plans,
  },
  {
    title: 'Save & publish',
    subItems: [{ subItemId: 0, name: 'Publish', finished: false }],
    sectionId: MembershipCreationSection.Publish,
  },
];

export function useHooks() {
  const { data: user } = useAuth();
  const { data: merchant } = useMerchant();
  const merchantId = parseInt(user?.primaryMerchantId || '');
  // if/when user can be multiple merchants this will need changing
  const apiClient = ApiClient();

  const { setFormValues, subscriptionData } = useFormData();
  const [currentSection, setCurrentSection] = useState(0);
  const [currentSubItem, setCurrentSubItem] = useState(0);
  const [categories, setCategories] = useState(defaultCategories);
  const [membership, setMembership] = useState<Membership | null>(null);

  const changePage = (
    sectionId: number,
    subItem: number,
    finished: MenuProgressStatus
  ) => {
    if (finished !== MenuProgressStatus.Locked) {
      setCurrentSection(sectionId);
      setCurrentSubItem(subItem);
    }
  };

  const changeSection = (direction: MembershipCreationDirection) => {
    setCurrentSubItem(0);
    setCurrentSection(currentSection + direction);
  };

  const changeSubItem = (direction: MembershipCreationDirection) => {
    let newSection = currentSection;
    let newSubItem = currentSubItem;

    if (
      direction === MembershipCreationDirection.Backwards &&
      currentSubItem === 0
    ) {
      newSection = currentSection + direction;
    }

    if (
      direction === MembershipCreationDirection.Forward &&
      categories[MembershipCreationSection.Plans].subItems.length ===
        currentSubItem
    ) {
      newSection = currentSection + direction;
    }

    newSubItem = currentSubItem + direction;
    setCurrentSection(newSection);
    setCurrentSubItem(newSubItem);
  };

  const savePlanToContext = (
    data: MembershipPlanInput,
    sectionComplete: boolean
  ) => {
    if (!sectionComplete) {
      subscriptionData.membershipPlans[currentSubItem] = {
        membershipPlanTitle: data.membershipPlanTitle,
        membershipPlanPrice: data.membershipPlanPrice,
        memberQuantity: data.memberQuantity,
        contractType: data.contractType,
        billingCycle: data.billingCycle,
        contractLength: data.contractLength,
        membershipProducts: data.membershipProducts,
      };

      AddPlanToCategories(data.membershipPlanTitle);
      if (currentSubItem === subscriptionData.membershipPlans.length - 1) {
        subscriptionData.membershipPlans.push({
          membershipPlanTitle: '',
          membershipPlanPrice: '',
          memberQuantity: '',
          contractType: ContractType.AUTO_RENEW,
          billingCycle: 'Every 1 month',
          contractLength: '1 year',
          membershipProducts: [],
        });
      }

      setFormValues(subscriptionData);
      setCurrentSubItem(currentSubItem + 1);
    } else {
      subscriptionData.membershipPlans[currentSubItem] = data;

      // send to controller
      setFormValues(subscriptionData);
      changeSection(MembershipCreationDirection.Forward);
    }
  };

  const AddPlanToCategories = (name: string) => {
    const currentPlans = categories[MembershipCreationSection.Plans].subItems;
    currentPlans[currentSubItem].name = name || `Plan ${currentPlans.length}`;
    currentPlans[currentSubItem].finished = true;

    if (currentSubItem === subscriptionData.membershipPlans.length - 1) {
      currentPlans.push({
        subItemId: categories[MembershipCreationSection.Plans].subItems.length,
        name: 'Experience',
        finished: false,
      });
    }

    setCategories([
      categories[MembershipCreationSection.Details],
      {
        title: 'Plans',
        subItems: currentPlans,
        sectionId: MembershipCreationSection.Plans,
      },
      categories[MembershipCreationSection.Publish],
    ]);
  };

  /**
   * Sets Membership to the database (create/update)
   * @param membershipInput
   * @returns boolean
   */
  const setMembershipDb = async (
    membershipInput: TitleAndDescriptionInput
  ): Promise<boolean> => {
    if (!merchantId) {
      console.error('No merchant found');
      return false;
    }

    if (!membership) {
      const newMembership = await apiClient.memberships.createMembership({
        merchantId: merchantId,
        name: membershipInput.membershipTitle,
        description: membershipInput.membershipDescription,
        agreementNb: merchant?.defaultServiceAgreementNb ?? '',
      });
      setMembership(newMembership);
      return !!membership;
    } else {
      const updatedMembership = await apiClient.memberships.updateMembership({
        merchantId: Number(user?.primaryMerchantId),
        membershipId: membership.id,
        name: membershipInput.membershipTitle,
        description: membershipInput.membershipDescription,
      });
      setMembership({
        ...membership,
        name: updatedMembership.name,
        description: updatedMembership.description,
      });
      return true;
    }
  };

  /**
   * Sets MembershipPlan to the database (create/update)
   * @param membershipPlanInput
   * @returns
   */
  const setMembershipPlanDb = async (
    membershipPlanInput: MembershipPlanInput
  ): Promise<boolean> => {
    if (!membership) {
      console.error('No membership has been saved');
      return false;
    }
    const billingCycle =
      billingCycleMap[
        membershipPlanInput.billingCycle as keyof typeof billingCycleMap
      ];
    const contractCycle =
      contractLengthMap[
        membershipPlanInput.contractLength as keyof typeof contractLengthMap
      ];

    if (membership && !membership?.membershipPlans[currentSubItem]) {
      // membership exists but plan does not
      const newPlan = await apiClient.membershipPlans.createPlan({
        subscriptionId: membership.id,
        name: membershipPlanInput.membershipPlanTitle,
        maxNbOfConsumers: Number(membershipPlanInput.memberQuantity),
        totalAmount: Number(membershipPlanInput.membershipPlanPrice), //is in cents?
        taxRateId: membershipPlanInput.taxRateId,
        billingCycleType: billingCycle.type,
        billingCycleLength: billingCycle.length,
        contractType: contractCycle.type,
        contractLength: contractCycle.length,
        autoRenew: membershipPlanInput.contractType === ContractType.AUTO_RENEW,
        membershipProducts:
          membershipPlanInput?.membershipProducts.map((x: Experience) => ({
            maxNumberOfUse: -1,
            productId: x.id,
          })) || [],
      });

      setMembership({
        ...membership,
        membershipPlans: [...membership.membershipPlans, newPlan],
      });
      return true;
    } else {
      const updatePlan = await apiClient.membershipPlans.updatePlan({
        subscriptionId: membership?.id,
        membershipPlanId: membership?.membershipPlans[currentSubItem].id,
        name: membershipPlanInput.membershipPlanTitle,
        maxNbOfConsumers: Number(membershipPlanInput.memberQuantity),
        totalAmount: Number(membershipPlanInput.membershipPlanPrice),
        billingCycleType: billingCycle.type,
        billingCycleLength: billingCycle.length,
        contractType: contractCycle.type,
        contractLength: contractCycle.length,
        autoRenew: membershipPlanInput.contractType === ContractType.AUTO_RENEW,
        membershipProducts:
          membershipPlanInput?.membershipProducts.map((x: Experience) => ({
            maxNumberOfUse: -1,
            productId: x.id,
          })) || [],
      });

      membership?.membershipPlans.splice(currentSubItem, 1, updatePlan);

      setMembership({
        ...membership,
      });
      return true;
    }
  };

  const publishAllPlansDb = async (): Promise<boolean> => {
    if (!membership) {
      return false;
    }

    const publishedPlans = await apiClient.memberships.publishMembershipPlans({
      membershipId: membership.id,
    });

    if (!!publishedPlans) {
      setFormValues(defaultSubscriptionCreationObject);
    }
    // context will also have to be cleared when plans are saved

    return !!publishedPlans;
  };

  return {
    currentSection,
    currentSubItem,
    categories,
    changePage,
    changeSection,
    changeSubItem,
    savePlanToContext,
    setMembershipDb,
    setMembershipPlanDb,
    publishAllPlansDb,
  };
}
