import { Grid } from '@silverstein-properties/inspirelabs-ui';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Login } from './components/Login';
import { VerifyCode } from './components/VerifyCode';
import { MultiFactor } from './components/MultiFactor';
import { LoginSuccess } from './components/LoginSuccess';
import { useNavigate } from 'react-router-dom';
import { useLogin } from '@/hooks/useLogin';
import {
  signOut,
  multiFactor,
  MultiFactorResolver,
  MultiFactorSession,
  MultiFactorUser,
  UserCredential,
  signInWithEmailAndPassword,
  sendEmailVerification,
  RecaptchaVerifier,
  getMultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  MultiFactorError,
} from 'firebase/auth';

import { auth } from '../../config/config';
import { useMutation } from '@tanstack/react-query';
import { ApiClient } from '@/api/apiClient';
import {
  FirebaseUserWithAccessToken,
  useFeatureFlags,
  useIsSuperAdmin,
  useMerchantProfile,
} from '@/hooks';
import { FirebaseError } from 'firebase/app';
import { formatPhoneNumber, getMaskedNumber } from '@/utils';
import { MerchantUserStatus, User } from '@/types';

export const LoginModule = (): JSX.Element => {
  const { is2FAEnabled } = useFeatureFlags();
  const recaptchaWrapperRef = useRef<HTMLDivElement>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [mobileNumber, setMobileNumber] = useState<string>();
  const [loginCode, setLoginCode] = useState('');
  const [smsResolver, setSmsResolver] = useState<MultiFactorResolver>();
  const [currentMultiFactorSession, setCurrentMultiFactorSession] =
    useState<MultiFactorSession>();
  const [recaptchaVerifier, setRecaptchaVerifier] =
    useState<RecaptchaVerifier>();
  const navigate = useNavigate();
  const { warning, setWarning } = useLogin();
  const { mutateAsync: updateUserAsync } = useMerchantProfile();
  const { data: isSuperAdmin, isLoading: isSuperAdminLoading } =
    useIsSuperAdmin();
  const apiClient = ApiClient();

  useEffect(() => {
    if (auth.currentUser) {
      const multiFactorUser = multiFactor(auth.currentUser);
      if (multiFactorUser.enrolledFactors.length > 0) {
        navigate('/dashboard');
      } else {
        if (is2FAEnabled) {
          verifyMobileNumber(multiFactorUser);
        }
      }
    }
  }, [auth.currentUser]);

  useEffect(() => {
    const verifier = new RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
      },
      auth
    );
    setRecaptchaVerifier(verifier);
  }, []);

  const resetRecaptcha = () => {
    if (recaptchaVerifier && recaptchaWrapperRef.current) {
      recaptchaVerifier.clear();
      recaptchaWrapperRef.current.innerHTML =
        '<div id="recaptcha-container"></div>';
    }
    setRecaptchaVerifier(setUpRecaptcha());
  };

  const signInMutation = useMutation<
    { multiFactorUser: MultiFactorUser; loginAttempt: UserCredential },
    FirebaseError,
    { email: string; password: string }
  >({
    mutationFn: async ({ email, password }) => {
      const loginAttempt = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const multiFactorUser = multiFactor(loginAttempt.user);
      return {
        multiFactorUser: multiFactorUser,
        loginAttempt: loginAttempt,
      };
    },
    onSuccess: data => {
      const user = data?.loginAttempt?.user as FirebaseUserWithAccessToken;
      const multiFactorUser = data?.multiFactorUser as MultiFactorUser;
      if (!user) {
        setWarning('Login error, please try again');
        return;
      }
      if (!user.emailVerified && is2FAEnabled) {
        sendEmailVerification(user);
        setWarning('Please verify your email address');
        return;
      }
      if (multiFactorUser.enrolledFactors.length === 0 && is2FAEnabled) {
        verifyMobileNumber(multiFactorUser);
      } else {
        getUserFromBackEnd({
          accessToken: user.accessToken,
          email: user.email || '',
        });
      }
    },
    onError: error => {
      if (error?.name === 'FirebaseError') {
        if (error?.code === 'auth/multi-factor-auth-required') {
          handleMultiFactorAuth(error);
        } else if (
          (!!error && error?.code === 'auth/user-not-found') ||
          error?.code === 'auth/wrong-password'
        ) {
          setWarning('Login error, please try again');
        } else {
          setWarning('Login error, please try again');
        }
      }
    },
  });

  const setUpRecaptcha = () => {
    return new RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
      },
      auth
    );
  };

  const { mutate: getUser, mutateAsync: getUserAsync } = useMutation({
    mutationFn: async ({
      accessToken,
      email,
    }: {
      accessToken: string;
      email: string;
    }) => {
      return await apiClient.users.getUserByEmail({
        accessToken,
        email,
      });
    },
  });

  const getUserFromBackEnd = useCallback(
    async ({ accessToken, email }: { accessToken: string; email: string }) => {
      getUser(
        {
          accessToken,
          email,
        },
        {
          onError: () => {
            signOut(auth);
            setWarning('Unexpected error. Please try again later');
          },
          onSuccess: async (myUser: User) => {
            if (
              myUser &&
              myUser.merchantUser?.status === MerchantUserStatus.ACTIVE
            ) {
              if (isSuperAdmin && !isSuperAdminLoading) {
                navigate('/my-accounts');
              } else {
                navigate('/dashboard');
              }
            } else {
              if (
                myUser &&
                myUser.merchantUser?.status !== MerchantUserStatus.ACTIVE &&
                (!myUser.merchantUser?.passwordResetTokenExpiry ||
                  myUser.merchantUser?.passwordResetTokenExpiry < new Date())
              ) {
                setWarning(
                  'Your invitation has expired. Please contact support'
                );
              }
              if (
                myUser &&
                (myUser.merchantUser?.status ===
                  MerchantUserStatus.PENDING_INVITATION ||
                  myUser.merchantUser?.status ===
                    MerchantUserStatus.PENDING_REGISTRATION)
              ) {
                setWarning(
                  'Your invitation is pending. Please review your email'
                );
              } else {
                // this should not really happen, unless there is a data
                // discrepancy between Firebase and the Hub DB
                setWarning('Unexpected error. Please try again later');
              }
              signOut(auth);
            }
          },
        }
      );
    },
    [isSuperAdmin, isSuperAdminLoading]
  );

  const loginWithSMS = async (code: string) => {
    try {
      const cred = PhoneAuthProvider.credential(loginCode, code);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      if (smsResolver) {
        const signedInUser = (
          await smsResolver.resolveSignIn(multiFactorAssertion)
        ).user as FirebaseUserWithAccessToken;
        await getUserFromBackEnd({
          accessToken: signedInUser.accessToken,
          email: signedInUser.email || '',
        });
      } else {
        if (auth.currentUser) {
          await multiFactor(auth.currentUser).enroll(
            multiFactorAssertion,
            !!mobileNumber ? getMaskedNumber(mobileNumber) : 'Phone number'
          );
          const user = await getUserAsync({
            accessToken: (auth.currentUser as FirebaseUserWithAccessToken)
              .accessToken,
            email: auth.currentUser.email || '',
          });
          if (user && mobileNumber) {
            updateUserAsync({
              id: user.id,
              phone: mobileNumber,
            });
          }
          setCurrentPage(4);
        }
      }
    } catch (error) {
      if ((error as FirebaseError)?.code === 'auth/invalid-verification-code') {
        setWarning('Invalid verification code');
      } else {
        setWarning('Error logging in with SMS');
      }
    }
  };

  const verifyMobileNumber = async (multiFactorUser: MultiFactorUser) => {
    const session = await multiFactorUser.getSession();
    setCurrentMultiFactorSession(session);
    setCurrentPage(2);
  };

  const handleMobileSubmit = async (mobileNumber: string) => {
    setWarning('');
    try {
      if (recaptchaVerifier) {
        setMobileNumber(formatPhoneNumber(mobileNumber));
        const phoneInfoOptions = {
          phoneNumber: mobileNumber,
          session: currentMultiFactorSession,
        };
        const phoneAuthProvider = new PhoneAuthProvider(auth);
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaVerifier
        );
        setLoginCode(verificationId);
        setCurrentPage(3);
      }
    } catch (error) {
      resetRecaptcha();
      if ((error as FirebaseError)?.code === 'auth/requires-recent-login') {
        setWarning('Please log out and log back in to continue');
      } else {
        setWarning('Error logging in with SMS');
      }
    } finally {
      resetRecaptcha();
    }
  };

  const resendCode = async () => {
    setWarning('');
    try {
      if (recaptchaVerifier) {
        if (mobileNumber && !smsResolver) {
          const phoneInfoOptions = {
            phoneNumber: mobileNumber,
            session: currentMultiFactorSession,
          };
          const phoneAuthProvider = new PhoneAuthProvider(auth);
          const verificationId = await phoneAuthProvider.verifyPhoneNumber(
            phoneInfoOptions,
            recaptchaVerifier
          );
          setLoginCode(verificationId);
        }
        if (smsResolver) {
          const phoneInfoOptions = {
            multiFactorHint: smsResolver.hints[0],
            session: smsResolver.session,
          };
          const phoneAuthProvider = new PhoneAuthProvider(auth);
          const verificationId = await phoneAuthProvider.verifyPhoneNumber(
            phoneInfoOptions,
            recaptchaVerifier
          );
          setMobileNumber(phoneInfoOptions.multiFactorHint.displayName || '');
          setLoginCode(verificationId);
        }
      }
    } catch (error) {
      resetRecaptcha();
      setWarning('Error resending code, please login again');
    }
  };

  const handleMultiFactorAuth = async (error: FirebaseError) => {
    setWarning('');
    try {
      if (recaptchaVerifier) {
        const resolver = getMultiFactorResolver(
          auth,
          error as MultiFactorError
        );
        const phoneInfoOptions = {
          multiFactorHint: resolver.hints[0],
          session: resolver.session,
        };
        const phoneAuthProvider = new PhoneAuthProvider(auth);
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaVerifier
        );
        setMobileNumber(phoneInfoOptions.multiFactorHint.displayName || '');
        setLoginCode(verificationId);
        setSmsResolver(resolver);
        setCurrentMultiFactorSession(resolver.session);
        setCurrentPage(3);
      }
    } catch (error) {
      resetRecaptcha();
      setWarning('Error logging in with SMS');
    } finally {
      resetRecaptcha();
    }
  };

  const logIn = async (email: string, password: string) => {
    setWarning('');
    if (!email || !password) {
      return setWarning('Please enter all required fields');
    }
    signInMutation.mutate({ email, password });
  };

  const goBack = () => {
    setCurrentPage(currentPage - 1);
  };

  const LoginSteps: { [index: number]: ReactNode } = {
    1: <Login logIn={logIn} warning={warning} />,
    2: (
      <MultiFactor
        warning={warning}
        handleMobileSubmit={handleMobileSubmit}
        goBack={goBack}
      />
    ),
    3: (
      <VerifyCode
        warning={warning}
        onAuthorize={loginWithSMS}
        mobileNumber={mobileNumber}
        onResendCode={resendCode}
        showEditNumber={!smsResolver}
        goBack={goBack}
      />
    ),
    4: <LoginSuccess />,
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={3}>
        {LoginSteps[currentPage]}
      </Grid>
      <div ref={recaptchaWrapperRef}>
        <div id="recaptcha-container"></div>
      </div>
    </Grid>
  );
};
