import { ContractType, MembershipPlan } from './MerchantMembership';
import { PaymentStatus } from './Payments';

export enum PlanPurchaseCancellationFeedback {
  TOO_EXPENSIVE = 'too_expensive', // It’s too expensive
  MISSING_FEATURES = 'missing_features', // Some features are missing
  SWITCHED_SERVICE = 'switched_service', // I’m switching to a different service
  UNUSED = 'unused', // I don’t use the service enough
  CUSTOMER_SERVICE = 'customer_service', // Customer service was less than expected
  TOO_COMPLEX = 'too_complex', // Ease of use was less than expected
  LOW_QUALITY = 'low_quality', // Quality was less than expected
  OTHER = 'other', // Other reason
}

export enum PlanPurchaseCancellationReason {
  CANCELLATION_REQUESTED = 'cancellation_requested',
  PAYMENT_DISPUTED = 'payment_disputed',
  PAYMENT_FAILED = 'payment_failed',
}

export interface PlanPurchaseCancellation {
  cancelledAt: Date;
  comment?: string;
  feedback?: PlanPurchaseCancellationFeedback;
  reason: PlanPurchaseCancellationReason;
}

export enum PlanPurchaseStatus {
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
  TRIALING = 'trialing',
  CANCELLED_BY_CONSUMER = 'cancelled_by_consumer', // aka cancelled
  CANCELLED_BY_MERCHANT = 'cancelled_by_merchant', // aka terminated
}

export enum PlanPurchaseSubscriptionStatus {
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  TRIALING = 'trialing',
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
  CANCELED = 'canceled',
  UNPAID = 'unpaid',
  EXPIRING = 'expiring',
}

export enum InvoiceStatus {
  DRAFT = 'draft',
  OPEN = 'open',
  PAID = 'paid',
  VOID = 'void',
  UNCOLLECTIBLE = 'uncollectible',
}

export enum InvoicePaymentStatus {
  UPCOMING = 'Upcoming',
  PROCESSING = 'Processing',
  SETTLED = 'Settled',
  DECLINED = 'Declined',
  CANCELED = 'Canceled',
  REFUNDED = 'Refunded',
  PARTIAL_REFUNDED = 'Partial Refunded',
  DISPUTED = 'Disputed',
}

export enum RefundReasons {
  duplicate = 'duplicate',
  fraudulent = 'fraudulent',
  requested_by_customer = 'requested_by_customer',
}

export enum PaymentIntentStatus {
  REQUIRES_PAYMENT_METHOD = 'requires_payment_method',
  REQUIRES_CONFIRMATION = 'requires_confirmation',
  REQUIRES_ACTION = 'requires_action',
  PROCESSING = 'processing',
  REQUIRES_CAPTURE = 'requires_capture',
  SUCCEEDED = 'succeeded',
  CANCELED = 'canceled',
}

export enum PlanConsumerStatus {
  REQUIRES_AGREEMENT_ACCEPTANCE = 'requires_agreement_acceptance',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PLACEHOLDER = 'placeholder',
}

export type PlanConsumer = {
  id: string;
  consumerId: string;
  status: PlanConsumerStatus;
  consumer?: MembershipPlanConsumer;
};

export type ProductUsage = {
  id: string;
  planConsumerId: string;
  maxNbOfUsage: number;
  currentUsage: number;
  productId: string;
};

export interface MembershipPlanConsumer {
  firstName: string;
  lastName: string;
  email: string;
  imageUrl?: string;
}

interface PlanConsumerUpdates {
  lastModifiedAt: Date;
  nextAllowedModificationAt: Date;
}

export type ProductPurchasesHistory = {
  id: string;
  // References to membership-product in membership-plan object
  subscriptionProductId: string;
  bookingId: string;
};

export type MembershipPlanPurchase = {
  id: string;
  primaryConsumerId: string;
  // TODO: Verify w/ Andres about adding the next 3 fields
  firstName?: string;
  lastName?: string;
  email?: string;
  billingCycleStart: Date;
  currentPeriodStart: Date;
  currentPeriodEnd: Date;
  subscriptionStatus: PlanPurchaseSubscriptionStatus;
  cancellationDetails?: PlanPurchaseCancellation;
  validUntil: Date;
  paymentId: string;
  status: PlanPurchaseStatus;
  paymentStatus: PaymentStatus;
  paymentMethods?: {
    id: string;
    brand: string;
    last4: string;
    isDefault: boolean;
  }[];

  planConsumersUpdates: PlanConsumerUpdates;

  membershipPlanId: string;
  membershipPlanSnapshot: MembershipPlan;
  membershipPlan?: MembershipPlan;

  productUsages: ProductUsage[];
  planConsumers: PlanConsumer[];
  productPurchasesHistory: ProductPurchasesHistory[];

  primaryConsumer?: MembershipPlanConsumer;
  balanceDetail?: ConsumerBalanceDetail;

  contractType: ContractType;
  createdUserId: string;
  createdAt: Date;
  updatedUserId?: string;
  updatedAt?: Date;
  deletedUserId?: string;
  deletedAt?: Date;
};

export class MembershipPlanPurchaseQuery {
  page?: number;
  pageSize?: number;
  searchTerm?: string;
  sortOrder?: 'asc' | 'desc';
}

export interface MembershipPlanPurchaseQueryResponse {
  purchases: MembershipPlanPurchase[];
  metadata: {
    totalItems: number;
    currentPage: number;
    pageSize: number;
  };
}

export interface ConsumerBalanceDetail {
  lastPayment: number;
  lastPaymentDate: Date;
  previousBalance: number;
  balanceDue: number;
  balanceDueDate: Date;
  planPurchaseId?: string;
}

export type MembershipPlanPurchaseInvoice = {
  id: string;
  subscriptionId: string;
  amountDue: number;
  amountPaid: number;
  amountRemaining: number;
  currency: string;
  status: InvoiceStatus;
  created: Date;
  dueDate: Date;
  paid: boolean;
  periodStart: Date;
  periodEnd: Date;
  host: string;
  invoicePdf: string;
  paymentIntent: PaymentIntent;
  attemptCount: number;
  attempted: boolean;
  nextPaymentAttempt?: Date;
};

export interface PaymentIntent {
  id: string;
  amount: number;
  amountCapturable: number;
  amountReceived: number;
  paymentMethod: PaymentMethod;
  lastPaymentError?: LastPaymentError;
  latestCharge?: LastCharge;
  status: PaymentIntentStatus;
}

export interface LastCharge {
  id: string;
  amount: number;
  amountCapturable: number;
  amountRefunded: number;
  captured: boolean;
  paid: boolean;
  refunded: boolean;
  disputed: boolean;
  failureCode: string;
  failureMessage: string;
  status: string;
  outcome: Outcome;
}

export interface Outcome {
  networkStatus: string;
  reason: string;
  riskLevel: string;
  riskScore: number;
  sellerMessage: string;
  type: string;
}

export interface LastPaymentError {
  charge: string;
  message: string;
  code: string;
  declineCode: string;
  docUrl: string;
  param: string;
  paymentMethod: PaymentMethod;
  type: string;
}

export interface PaymentMethod {
  id: string;
  object: string;
  card: Card;
  created: number;
  customer: string;
  livemode: boolean;
  type: string;
}

export interface Card {
  brand: string;
  country: string;
  exp_month: number;
  exp_year: number;
  fingerprint: string;
  funding: string;
  generated_from: null;
  last4: string;
}
