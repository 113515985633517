import { TEXT_AREA_LIMIT_MEDIUM } from '@/constants';
import { RefundReasons } from '@/types';
import { IsEnum, IsNotEmpty, MaxLength, ValidateIf } from 'class-validator';
import { PriceMaxValue, PriceMinValue } from './validations';

export class RefundInput {
  @IsNotEmpty({
    message:
      "Enter any refund amount you'd like to refund up to a maximum of the original transaction value",
  })
  @PriceMinValue(1, {
    message: 'Your refund amount must be greater than $1',
  })
  @PriceMaxValue('maxAmount', {
    message: args =>
      `Your amount cannot be greater than $${
        (args.object as RefundInput).maxAmount
      }`,
  })
  amount: number | string;

  @ValidateIf(o => o.type === 'refund')
  @IsEnum(RefundReasons)
  @IsNotEmpty({
    message: 'Select a reason for your refund',
  })
  reason: RefundReasons | '';

  @MaxLength(TEXT_AREA_LIMIT_MEDIUM, {
    message: 'Please enter a shorter message.',
  })
  notes?: string;

  maxAmount: number;
}
