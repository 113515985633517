import {
  Typography,
  MenuItem,
  InputField,
  Stack,
  useTheme,
  Link,
} from '@silverstein-properties/inspirelabs-ui';
import { Controller, useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import {
  BillingCycleTypeDisplay,
  Experience,
  MembershipPlan,
  ExperienceStatus,
  SubscriptionType,
  ContractType,
} from '@/types';
import { PlanEditInput } from '@/classes/MembershipEditInput';
import { FormHelperText, SxProps } from '@mui/material';
import { MembershipProductsList, ProductListDialog } from '@/components';
import { useEffect, useState } from 'react';
import { useExperiences, useMerchantPlatformFee, useTaxRates } from '@/hooks';
import {
  pluralize,
  mapTaxRateOptions,
  formatPercentage,
  filterExperienceByStatus,
} from '@/utils';
import {
  billingCycleMap,
  contractLengthMap,
  contractTypeMap,
  convertToMonths,
  determineBillingCycleFromPlan,
  memberQuantityMap,
  renderContractLength,
} from '@/utils/membershipUtils';
import { ScreensViews } from '@/hooks/useMembershipEdit';
import { TAX_EXEMPT_PRICING_NO_LABEL } from '@/constants';

const resolver = classValidatorResolver(PlanEditInput);

export type PlanEditPropsType = {
  membershipPlan: MembershipPlan;
  onFormSubmit: (data: PlanEditInput) => Promise<void>;
};

export const EditPlan = ({
  membershipPlan,
  onFormSubmit: originalOnFormSubmit,
}: PlanEditPropsType) => {
  const theme = useTheme();
  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm<PlanEditInput>({
    resolver,
    mode: 'onTouched',
    defaultValues: {
      membershipPlanTitle: membershipPlan.name || '',
      membershipPlanPrice: membershipPlan?.totalAmount?.toString() || '',
      memberQuantity: membershipPlan.maxNbOfConsumers || undefined,
      contractType: membershipPlan.autoRenew
        ? ContractType.AUTO_RENEW
        : ContractType.ONE_TIME,
      billingCycle: determineBillingCycleFromPlan(
        membershipPlan.billingCycleLength,
        membershipPlan.billingCycleType
      ) as keyof typeof billingCycleMap,
      contractLength: `${pluralize(
        membershipPlan.contractLength,
        membershipPlan.contractType
      )}` as keyof typeof contractLengthMap,
      membershipProducts: membershipPlan.membershipProducts.map(
        x => x?.product
      ),
      taxRateId: membershipPlan?.taxRateId,
    },
  });
  const modifiedOnFormSubmit = async (data: PlanEditInput) => {
    // Check if billing cycle is valid
    if (!isSelectionValid) {
      return; // Prevent form submission
    }

    // If everything is valid, proceed with the original submission logic
    await originalOnFormSubmit(data);
  };

  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const { data: experiences } = useExperiences();
  const { data: platformFee } = useMerchantPlatformFee(
    SubscriptionType.MEMBERSHIP
  );
  const { data: taxRates } = useTaxRates();
  const [membershipProducts, setMembershipProducts] = useState<Experience[]>(
    membershipPlan.membershipProducts.reduce((acc, cur) => {
      if (cur?.product) {
        acc.push(cur?.product);
      }
      return acc;
    }, [] as Experience[])
  );
  const [isSelectionValid, setIsSelectionValid] = useState(true);

  // Logic to determine if billingCycle <= contractLength
  useEffect(() => {
    const billingCycleKey =
      (watch('billingCycle') as keyof typeof billingCycleMap) ??
      'Every 1 month';
    const contractLengthKey =
      (watch('contractLength') as keyof typeof contractLengthMap) ?? '1 month';

    const billingCycleInMonths = convertToMonths(
      billingCycleMap[billingCycleKey]?.length,
      billingCycleMap[billingCycleKey]?.type
    );
    const contractLengthInMonths = convertToMonths(
      contractLengthMap[contractLengthKey]?.length,
      contractLengthMap[contractLengthKey]?.type
    );

    setIsSelectionValid(billingCycleInMonths <= contractLengthInMonths);
  }, [watch('contractLength'), watch('billingCycle')]);

  const onProductSelect = (ids: string[]) => {
    const newMembershipProducts = experiences?.filter(x => ids.includes(x.id));

    // use filter instead?
    setValue('membershipProducts', newMembershipProducts || [], {
      shouldTouch: true,
    });
    setMembershipProducts(newMembershipProducts || []);
  };

  // Reusable styles for same-line inputs (e.g. price and billing cycle)
  const borderLeftStyle: SxProps = {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  };
  const borderRightStyle: SxProps = {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    '& .MuiOutlinedInput-notchedOutline': {
      borderRight: '1px solid transparent',
    },
  };

  // if no members => status: "inactive"
  return (
    <Stack spacing={theme.spacers.xxl}>
      <Typography variant="h5">{membershipPlan.name}</Typography>
      <form
        id={`${ScreensViews.EDIT_PLANS}Form`}
        onSubmit={handleSubmit(modifiedOnFormSubmit)}
      >
        <Stack spacing={theme.spacers.m} width="100%">
          <Controller
            control={control}
            name="membershipPlanTitle"
            render={({ field }) => (
              <InputField
                {...field}
                label="Plan title"
                data-testid="planEditTitle"
                error={!!errors.membershipPlanTitle}
                helperText={
                  errors.membershipPlanTitle
                    ? errors.membershipPlanTitle.message
                    : 'For example: Household of 4'
                }
              />
            )}
          />

          {!membershipPlan.hasMembers ? (
            <>
              <Controller
                control={control}
                name="memberQuantity"
                render={({ field }) => (
                  <InputField
                    {...field}
                    select
                    id="member-quantity"
                    label="No. of members"
                    data-testid="memberQuantity"
                    dropdownItems={memberQuantityMap}
                    error={!!errors.memberQuantity}
                    helperText={errors.memberQuantity?.message}
                  />
                )}
              />

              <Stack direction="row">
                <Controller
                  control={control}
                  name="membershipPlanPrice"
                  render={({ field }) => (
                    <InputField
                      {...field}
                      isPriceInput
                      label="Price"
                      sx={borderRightStyle}
                      error={!!errors.membershipPlanPrice}
                      helperText={
                        errors.membershipPlanPrice
                          ? errors.membershipPlanPrice?.message
                          : `Earnings are subjected to a ${formatPercentage(
                              platformFee
                            )} platform fee`
                      }
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="billingCycle"
                  render={({ field }) => (
                    <InputField
                      {...field}
                      select
                      id="billingCycle"
                      label="Billing cycle"
                      sx={borderLeftStyle}
                      error={!!errors.billingCycle || !isSelectionValid}
                      helperText={
                        errors.billingCycle
                          ? errors.billingCycle?.message
                          : isSelectionValid
                          ? 'Members will be charged on this frequency'
                          : "Billing cycle can't be longer than contract length"
                      }
                    >
                      {Object.keys(billingCycleMap).map((value, i) => {
                        return (
                          <MenuItem key={i + value} value={value}>
                            {value}
                          </MenuItem>
                        );
                      })}
                    </InputField>
                  )}
                />
              </Stack>
              <Controller
                control={control}
                name="taxRateId"
                render={({ field }) => (
                  <InputField
                    {...field}
                    select
                    label="Tax rate"
                    error={!!errors.taxRateId}
                    disabled={false}
                    dropdownItems={
                      taxRates
                        ? [
                            TAX_EXEMPT_PRICING_NO_LABEL,
                            ...mapTaxRateOptions(taxRates, false),
                          ]
                        : [{ id: 0, label: 'Loading...', value: '0' }]
                    }
                    helperText={
                      errors.taxRateId
                        ? errors.taxRateId.message
                        : 'The tax rate applied to this plan'
                    }
                  />
                )}
              />
              <Stack direction="row">
                <Controller
                  control={control}
                  name="contractLength"
                  render={({ field }) => (
                    <InputField
                      {...field}
                      select
                      label="Contract length"
                      sx={borderRightStyle}
                      error={!!errors.contractLength}
                      helperText={
                        errors.contractLength
                          ? errors.contractLength.message
                          : "Member's duration of commitment"
                      }
                    >
                      {Object.keys(contractLengthMap).map((value, i) => (
                        <MenuItem key={i + value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </InputField>
                  )}
                />
                <Controller
                  control={control}
                  name="contractType"
                  render={({ field }) => (
                    <InputField
                      {...field}
                      select
                      id="contract-type"
                      label="Contract type"
                      dropdownItems={contractTypeMap}
                      sx={borderLeftStyle}
                      error={!!errors.contractType}
                      helperText={
                        errors.contractType
                          ? errors.contractType?.message
                          : "The full duration of a user's subscription"
                      }
                    />
                  )}
                />
              </Stack>
            </>
          ) : (
            <Stack>
              <Typography variant="subtitle2">
                ${membershipPlan.price}/
                {BillingCycleTypeDisplay[membershipPlan.billingCycleType]}
              </Typography>
              <Typography variant="body1">
                {pluralize(membershipPlan.maxNbOfConsumers, 'member').replace(
                  ' ',
                  '-'
                )}
              </Typography>
              <Typography variant="body1">
                {renderContractLength(membershipPlan)}
              </Typography>
              <Typography variant="body2" color="primary.medium">
                {membershipPlan.autoRenew ? 'Auto-Renew' : 'Single Payment'}
              </Typography>
            </Stack>
          )}
        </Stack>
        <ProductListDialog
          title="Connected experiences"
          agree="Update List"
          disagree="Cancel"
          open={isProductModalOpen}
          onClose={() => setIsProductModalOpen(false)}
          onConfirm={onProductSelect}
          currentSelectedProducts={membershipProducts}
          selectedProductsAreReadOnly={membershipPlan?.hasMembers}
          products={filterExperienceByStatus(experiences || [], [
            ExperienceStatus.Approved,
            ExperienceStatus.Published,
          ])}
        />
      </form>

      <Stack spacing={theme.spacers.s}>
        <MembershipProductsList
          isLoading={false}
          title="Included experiences"
          productsInfo={(membershipProducts || []).map(x => ({
            id: x.id,
            title: x?.title || '',
          }))}
        />

        <Link href="#" onClick={() => setIsProductModalOpen(true)}>
          Edit experience list
        </Link>

        <FormHelperText error={membershipProducts.length === 0}>
          {membershipProducts.length === 0 && 'Select Experiences to add'}
        </FormHelperText>
      </Stack>
    </Stack>
  );
};
