import { auth, queryClient } from '@/config';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { User } from 'types';
import { AuthError, User as FirebaseUser } from 'firebase/auth';
import { useIdToken } from 'react-firebase-hooks/auth';
import { useEffect } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';

export interface FirebaseUserWithAccessToken extends FirebaseUser {
  accessToken: string;
}

export const useAuth = () => {
  const [user, loading, error] = useIdToken(auth);
  const firebaseUser = user as FirebaseUserWithAccessToken;
  const ldClient = useLDClient();

  useEffect(() => {
    const authError = error as AuthError;
    if (authError) {
      console.error(error);
      if (
        authError.code === 'auth/id-token-expired' ||
        authError.code === 'auth/invalid-id-token'
      ) {
        firebaseUser.getIdToken(true);
      }
    }
    if (!user && !error) {
      backendUserQuery.refetch();
      backendUserQuery.remove();
    }
  }, [user, error]);

  const getUserFromBackEnd = async (
    email: string | null,
    accessToken?: string
  ) => {
    if (email && accessToken) {
      const response = await axios.get<User>(
        `${
          process.env.REACT_APP_HUB_SERVICE_BASE_URL
        }/users/?email=${encodeURIComponent(email)}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const user = response.data;
      ldClient?.identify({
        key: `user-${user.id}-${user.merchantUser?.merchantId}`,
        kind: 'user',
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        custom: {
          userId: user.id,
          merchantId: user.merchantUser?.merchantId || '',
          role: user.roles?.map(x => x.role) || [],
          hasAtLeastOneExperience: user.hasAtLeastOneExperience,
        },
      });
      return { ...response.data, accessToken: accessToken };
    } else {
      return null;
    }
  };

  const backendUserQuery = useQuery<User | null>(
    ['userFromBackend'],
    async () =>
      getUserFromBackEnd(firebaseUser?.email, await firebaseUser?.getIdToken()),
    { enabled: !!firebaseUser }
  );

  const updateUser = async (newUser: User) => {
    // Update the backend user query data
    queryClient.setQueryData(['userFromBackend'], newUser);

    // Force refetch of the user data
    await backendUserQuery.refetch();
  };

  return {
    ...backendUserQuery,
    hasFirebaseUser: !!firebaseUser,
    firebaseUser: firebaseUser,
    isFirebaseLoading: loading,
    updateUser,
  };
};
