import usePartialCreateExperienceMutation from '@/hooks/mutations/usePartialCreateExperienceMutation';
import { BookingMethod, Experience, ProductTypes } from '@/types';
import {
  ApplicantsIcon,
  CateringIcon,
  CloseIcon,
  Dialog,
  LinkIcon,
  List,
  ScheduleIcon,
  SpacesIcon,
  Stack,
  Typography,
  useTheme,
} from '@silverstein-properties/inspirelabs-ui';
import { useNavigate } from 'react-router';
import {
  CloseButton,
  StyledListItem,
  StyledListItemButton,
} from './SelectBookingTypeDialog.styles';
import { useFeatureFlags } from '@/hooks';
import { DialogContent, DialogTitle } from '@mui/material';
const SelectBookingTypeDialog = ({
  open,
  onCloseModal,
}: {
  open: boolean;
  onCloseModal: () => void;
}) => {
  const { isBookingRequestEnabled, isCateringEnabled, isAdvertEnabled } =
    useFeatureFlags();
  const navigate = useNavigate();
  const theme = useTheme();
  const { mutateAsync } = usePartialCreateExperienceMutation();
  const onClickBookingType = async (bookingType: BookingMethod) => {
    let customData: Partial<Experience> = {};
    switch (bookingType) {
      case BookingMethod.DELIVERY:
        customData = {
          type: ProductTypes.FOOD_SERVICE,
        };
        break;
      case BookingMethod.ADVERT:
        customData = {
          type: ProductTypes.ADVERT,
        };
        break;
      case BookingMethod.REQUEST:
        customData = {
          groupBookingsAllowed: true,
        };
        break;
      case BookingMethod.SPACE:
        customData = {
          type: ProductTypes.SPACE,
        };
        break;
    }
    const newExperience = await mutateAsync({
      data: {
        bookingMethods: [bookingType],
        ...customData,
        buildingIds: [],
      },
    });
    navigate(`/experiences/${newExperience.id}`, {
      state: { from: document.location.pathname },
    });
    onCloseModal();
  };
  return (
    <Dialog
      open={open}
      onClose={() => onCloseModal()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          maxWidth: '500px',
          width: '100%',
          maxHeight: '600px',
          paddingBottom: ({ spacing }) => spacing(3),
          borderRadius: ({ spacing }) => spacing(3),
        },
      }}
    >
      <DialogTitle>
        <Stack flexDirection="row" justifyContent="center" paddingY={1}>
          <CloseButton size="small" onClick={onCloseModal}>
            <CloseIcon width={30} />
          </CloseButton>
          <Typography variant="subtitle2">Select Booking type</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack gap={16} justifyContent="center" justifyItems="center">
          <List>
            <StyledListItem
              disablePadding
              onClick={() => {
                onCloseModal();
              }}
            >
              <StyledListItemButton
                onClick={() => {
                  onClickBookingType(BookingMethod.INSTANT);
                }}
              >
                <Stack flexDirection="row">
                  <Stack paddingRight={theme.spacing(3)}>
                    <Typography
                      variant="subtitle1"
                      data-testid="bookingTypeInstant"
                    >
                      Instant booking
                    </Typography>
                    <Typography variant="body2" color="primary.medium">
                      I provide a fixed schedule where customers can book
                      instantly
                    </Typography>
                  </Stack>
                  <ScheduleIcon width={80} />
                </Stack>
              </StyledListItemButton>
            </StyledListItem>
            {isBookingRequestEnabled && (
              <StyledListItem
                disablePadding
                sx={{
                  marginTop: 2,
                }}
                onClick={() => {
                  onCloseModal();
                }}
              >
                <StyledListItemButton
                  onClick={() => onClickBookingType(BookingMethod.REQUEST)}
                >
                  <Stack flexDirection="row">
                    <Stack paddingRight={theme.spacing(3)}>
                      <Typography
                        variant="subtitle1"
                        data-testid="bookingTypeRequest"
                      >
                        Booking request
                      </Typography>
                      <Typography variant="body2" color="primary.medium">
                        Customers will contact me to set up final scope of
                        service, date and time.
                      </Typography>
                    </Stack>
                    <ApplicantsIcon width={80} />
                  </Stack>
                </StyledListItemButton>
              </StyledListItem>
            )}
            {isCateringEnabled && (
              <StyledListItem
                disablePadding
                sx={{
                  marginTop: 2,
                }}
                onClick={() => {
                  onCloseModal();
                }}
              >
                <StyledListItemButton
                  onClick={() => onClickBookingType(BookingMethod.DELIVERY)}
                >
                  <Stack flexDirection="row">
                    <Stack paddingRight={theme.spacing(3)}>
                      <Typography
                        variant="subtitle1"
                        data-testid="bookingTypeCatering"
                      >
                        Catering
                      </Typography>
                      <Typography variant="body2" color="primary.medium">
                        Customers can choose amongst menu items from Square
                      </Typography>
                    </Stack>
                    <CateringIcon width={80} />
                  </Stack>
                </StyledListItemButton>
              </StyledListItem>
            )}
            {isAdvertEnabled && (
              <StyledListItem
                disablePadding
                sx={{
                  marginTop: 2,
                }}
                onClick={() => {
                  onCloseModal();
                }}
              >
                <StyledListItemButton
                  onClick={() => onClickBookingType(BookingMethod.ADVERT)}
                >
                  <Stack flexDirection="row">
                    <Stack paddingRight={theme.spacing(3)}>
                      <Typography
                        variant="subtitle1"
                        data-testid="bookingTypeAdvert"
                      >
                        Advert
                      </Typography>
                      <Typography variant="body2" color="primary.medium">
                        Customers can see my advertisement before being
                        redirected to my link
                      </Typography>
                    </Stack>
                    <LinkIcon width={80} />
                  </Stack>
                </StyledListItemButton>
              </StyledListItem>
            )}
            <StyledListItem
              disablePadding
              sx={{
                marginTop: 2,
              }}
              onClick={() => {
                onCloseModal();
              }}
            >
              <StyledListItemButton
                onClick={() => onClickBookingType(BookingMethod.SPACE)}
              >
                <Stack flexDirection="row">
                  <Stack paddingRight={theme.spacing(3)}>
                    <Typography
                      variant="subtitle1"
                      data-testid="bookingTypeSpaces"
                    >
                      Spaces
                    </Typography>
                    <Typography variant="body2" color="primary.medium">
                      You offer commercial spaces for booking
                    </Typography>
                  </Stack>
                  <SpacesIcon width={64} />
                </Stack>
              </StyledListItemButton>
            </StyledListItem>
          </List>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
export { SelectBookingTypeDialog };
