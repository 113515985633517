import { merchantVisibilityConfig } from '@/config/merchantVisibilityConfig';
import {
  BookingMethod,
  CustomExperienceCheck,
  Experience,
  ExperienceStatus,
  PriceType,
  PurchaseMethods,
  RecursiveKeyOf,
} from '@/types';

// Extract the type of "experiencesDetails" from merchantVisibilityConfig.
export type ExperiencesDetailId =
  keyof typeof merchantVisibilityConfig.standard.experiencesDetails;

// Need to add type for "id" prop b/c we'll need to use "id" to lookup the visibility config in "src/config/merchantVisibilityConfig.ts"
// Now all "id" will be type-safe. New "id" must be added to "merchantVisibilityConfig.ts".
export interface ExperienceDetailsMenuType {
  id: ExperiencesDetailId;
  image: string;
  title: string;
  emptyMessage?: string;
  visibilityCheck: BookingMethod[];
  canEdit: boolean;
  showCompleteInfoCheck: boolean;
  completeInfoCheck: {
    [Property in BookingMethod]: (
      | RecursiveKeyOf<Experience>
      | CustomExperienceCheck
    )[];
  };
}

export const ALERT_DIALOGS: { [key: string]: { [key: string]: string } } = {
  cancelation: {
    title: 'Update cancellation policy?',
    image: '',
    message:
      'Changes do not apply to participants that are already booked. If you wish to apply changes to booked users, you need to refund/cancel user booking and ask them to re-signup',
  },
  pricing: {
    title: 'Update price?',
    image: '/images/sections/credits.svg',
    message:
      'Changes do not apply to participants that are already booked. If you wish to apply changes to booked users, you need to refund/cancel user booking and ask them to re-signup',
  },
  requirements: {
    title: 'Update requirements?',
    image: '/images/sections/requirements.svg',
    message:
      'Changes do not apply to participants that are already booked. You must notify your participants if this is a significant change for their event. If you wish to apply changes to booked participants you need to refund/cancel participant booking and ask them to re-signup',
  },
  location: {
    title: 'Update location?',
    image: '/images/sections/location.svg',
    message:
      'Changes do not apply to participants that are already booked. If you wish to apply changes to booked users, you need to refund/cancel user booking and ask them to re-signup Changes do not apply to the meeting point of scheduled events. Ensure you update your meeting points accordingly on the event listing.',
  },
};

export const EXPERIENCE_DETAIL_MENUS: ExperienceDetailsMenuType[] = [
  {
    id: 'basicInfo',
    image: '/images/sections/experiences.svg',
    title: 'Basic info',
    emptyMessage:
      "Add some basic info regarding the experience you'd like to provide.",
    visibilityCheck: [
      BookingMethod.INSTANT,
      BookingMethod.REQUEST,
      BookingMethod.DELIVERY,
      BookingMethod.ADVERT,
      BookingMethod.SPACE,
    ],
    canEdit: true,
    showCompleteInfoCheck: true,
    completeInfoCheck: {
      [BookingMethod.INSTANT]: ['category.info', 'category.type'],
      [BookingMethod.REQUEST]: ['category.info', 'category.type'],
      [BookingMethod.DELIVERY]: ['category.info', 'category.type'],
      [BookingMethod.ADVERT]: ['category.info', 'category.type'],
      [BookingMethod.SPACE]: ['category.info', 'category.type'],
    },
  },
  {
    id: 'titleAndDescription',
    image: '/images/sections/about.svg',
    title: 'Title & description',
    emptyMessage:
      'Tell us more about the experience. Provide specific plans from start to finish. Describe what makes it special.',
    visibilityCheck: [
      BookingMethod.INSTANT,
      BookingMethod.REQUEST,
      BookingMethod.DELIVERY,
      BookingMethod.ADVERT,
      BookingMethod.SPACE,
    ],
    canEdit: true,
    showCompleteInfoCheck: true,
    completeInfoCheck: {
      [BookingMethod.INSTANT]: [
        'title',
        'description',
        'duration.hours',
        'duration.minutes',
      ],
      [BookingMethod.REQUEST]: ['title', 'description'],
      [BookingMethod.DELIVERY]: ['title', 'description'],
      [BookingMethod.ADVERT]: ['title', 'description'],
      [BookingMethod.SPACE]: ['title', 'description'],
    },
  },
  {
    id: 'photos',
    image: '/images/sections/photo.svg',
    title: 'Photos',
    emptyMessage: 'Add great photos that best represent your experience.',
    visibilityCheck: [
      BookingMethod.INSTANT,
      BookingMethod.REQUEST,
      BookingMethod.DELIVERY,
      BookingMethod.ADVERT,
      BookingMethod.SPACE,
    ],
    canEdit: true,
    showCompleteInfoCheck: true,
    completeInfoCheck: {
      [BookingMethod.INSTANT]: ['coverPhoto'],
      [BookingMethod.REQUEST]: ['coverPhoto'],
      [BookingMethod.DELIVERY]: ['coverPhoto'],
      [BookingMethod.ADVERT]: ['coverPhoto'],
      [BookingMethod.SPACE]: ['coverPhoto'],
    },
  },
  {
    id: 'squareConnection',
    image: '/images/sections/profile.svg',
    title: 'Square Connection',
    emptyMessage:
      'Include your Square credentials and location Id for get access to the menu information.',
    visibilityCheck: [BookingMethod.DELIVERY],
    canEdit: true,
    showCompleteInfoCheck: true,
    completeInfoCheck: {
      [BookingMethod.INSTANT]: [],
      [BookingMethod.REQUEST]: [],
      [BookingMethod.DELIVERY]: [
        'squareConfiguration.accessToken',
        'squareConfiguration.clientEnvironment',
        'squareConfiguration.locationId',
      ],
      [BookingMethod.ADVERT]: [],
      [BookingMethod.SPACE]: [],
    },
  },
  {
    id: 'advertUrl',
    image: '/images/sections/requirements.svg',
    title: 'URL',
    emptyMessage: 'To redirect the user to your web-browser on mobile.',
    visibilityCheck: [BookingMethod.ADVERT],
    canEdit: true,
    showCompleteInfoCheck: true,
    completeInfoCheck: {
      [BookingMethod.INSTANT]: [],
      [BookingMethod.REQUEST]: [],
      [BookingMethod.DELIVERY]: [],
      [BookingMethod.ADVERT]: ['advertUrl'],
      [BookingMethod.SPACE]: [],
    },
  },
  {
    id: 'location',
    image: '/images/sections/location.svg',
    title: 'Location',
    emptyMessage: 'Where is the experience taking place?',
    visibilityCheck: [
      BookingMethod.INSTANT,
      BookingMethod.REQUEST,
      BookingMethod.DELIVERY,
      BookingMethod.ADVERT,
      BookingMethod.SPACE,
    ],
    canEdit: true,
    showCompleteInfoCheck: true,
    completeInfoCheck: {
      [BookingMethod.INSTANT]: [
        'buildingIds',
        'location.environmentType',
        'location.address',
      ],
      [BookingMethod.REQUEST]: ['buildingIds', 'location.environmentType'],
      [BookingMethod.DELIVERY]: ['buildingIds'],
      [BookingMethod.ADVERT]: ['buildingIds'],
      [BookingMethod.SPACE]: [
        'buildingIds',
        'location.environmentType',
        'location.address',
      ],
    },
  },
  {
    id: 'availability',
    image: '/images/sections/schedule.svg',
    title: 'Availability',
    emptyMessage: 'Set an availability schedule for the space',
    // Hidden for now, will be enabled when we're ready to launch the feature
    visibilityCheck: [],
    canEdit: true,
    showCompleteInfoCheck: true,
    completeInfoCheck: {
      [BookingMethod.INSTANT]: [],
      [BookingMethod.REQUEST]: [],
      [BookingMethod.DELIVERY]: [],
      [BookingMethod.ADVERT]: [],
      [BookingMethod.SPACE]: ['availability.reservationMethod'],
    },
  },
  {
    id: 'aboutTheHost',
    image: '/images/sections/profile.svg',
    title: 'Host',
    emptyMessage:
      'Add a short bio. Share your passion as a host and experience provider.',
    visibilityCheck: [BookingMethod.INSTANT, BookingMethod.REQUEST],
    canEdit: true,
    showCompleteInfoCheck: true,
    completeInfoCheck: {
      [BookingMethod.INSTANT]: [
        'host.firstName',
        'host.lastName',
        'host.hostPhoto',
        'host.bio',
        'host.phoneNumber',
      ],
      [BookingMethod.REQUEST]: [
        'host.firstName',
        'host.lastName',
        'host.hostPhoto',
        'host.bio',
        'host.phoneNumber',
      ],
      [BookingMethod.DELIVERY]: [],
      [BookingMethod.ADVERT]: [],
      [BookingMethod.SPACE]: [],
    },
  },
  {
    id: 'pricing',
    image: '/images/sections/credits.svg',
    title: 'Pricing',
    emptyMessage:
      'Setup pricing for your experience. Experiences can be free or paid.',
    visibilityCheck: [
      BookingMethod.INSTANT,
      BookingMethod.REQUEST,
      BookingMethod.SPACE,
    ],
    canEdit: true,
    showCompleteInfoCheck: true,
    completeInfoCheck: {
      [BookingMethod.INSTANT]: [
        {
          // For member exclusive pricing, ignore basePrice's total and net amounts
          key: 'acceptedPurchaseMethods',
          completeInfoCheck: {
            [PurchaseMethods.SUBSCRIPTION]: ['basePrice.priceType'],
            [PurchaseMethods.ONE_TIME_MONEY_PURCHASE]: [
              'basePrice.totalAmount',
              'basePrice.netAmount',
            ],
            [PurchaseMethods.FIDELITY_POINTS]: [
              'basePrice.totalAmount',
              'basePrice.netAmount',
            ],
            [PurchaseMethods.VOUCHER]: [
              'basePrice.totalAmount',
              'basePrice.netAmount',
            ],
          },
        },
      ],
      [BookingMethod.REQUEST]: [
        {
          key: 'basePrice.priceType',
          completeInfoCheck: {
            [PriceType.PRICE_PER_PERSON]: [
              'basePrice.totalAmount',
              'basePrice.netAmount',
            ],
            [PriceType.FLAT_FEE]: [
              'basePrice.totalAmount',
              'basePrice.netAmount',
            ],
            [PriceType.VIA_QUOTE]: ['basePrice.priceType'],
          },
        },
      ],
      [BookingMethod.DELIVERY]: [],
      [BookingMethod.ADVERT]: [],
      [BookingMethod.SPACE]: [
        {
          // For member exclusive pricing, ignore basePrice's total and net amounts
          key: 'acceptedPurchaseMethods',
          completeInfoCheck: {
            [PurchaseMethods.SUBSCRIPTION]: ['basePrice.priceType'],
            [PurchaseMethods.ONE_TIME_MONEY_PURCHASE]: [
              'basePrice.totalAmount',
              'basePrice.netAmount',
            ],
            [PurchaseMethods.FIDELITY_POINTS]: [
              'basePrice.totalAmount',
              'basePrice.netAmount',
            ],
            [PurchaseMethods.VOUCHER]: [
              'basePrice.totalAmount',
              'basePrice.netAmount',
            ],
          },
        },
      ],
    },
  },
  {
    id: 'capacity',
    image: '/images/sections/community.svg',
    title: 'RSVP & Capacity',
    emptyMessage:
      'All experiences are RSVP. Let us know if there is a maximum capacity.',
    visibilityCheck: [
      BookingMethod.INSTANT,
      BookingMethod.REQUEST,
      BookingMethod.DELIVERY,
      BookingMethod.SPACE,
    ],
    canEdit: true,
    showCompleteInfoCheck: true,
    completeInfoCheck: {
      [BookingMethod.INSTANT]: ['capacity.max'],
      [BookingMethod.REQUEST]: ['capacity.max'],
      [BookingMethod.DELIVERY]: ['capacity.max'],
      [BookingMethod.ADVERT]: [],
      [BookingMethod.SPACE]: ['capacity.max'],
    },
  },
  {
    id: 'amenities',
    image: '/images/sections/collaboration.svg',
    title: 'Amenities',
    emptyMessage:
      'Let us know what amenities are include in the space price and whether there add-ons that are additional to the booking fee.',
    // Hidden for now, will be enabled when we're ready to launch the feature
    visibilityCheck: [],
    canEdit: true,
    showCompleteInfoCheck: true,
    completeInfoCheck: {
      [BookingMethod.INSTANT]: [],
      [BookingMethod.REQUEST]: [],
      [BookingMethod.DELIVERY]: [],
      [BookingMethod.ADVERT]: [],
      [BookingMethod.SPACE]: ['amenities'],
    },
  },
  {
    id: 'requirements',
    image: '/images/sections/requirements.svg',
    title: 'Requirements',
    emptyMessage:
      'Add special requirements. Let the guests know what you will provide and whether they need to bring anything.',
    visibilityCheck: [
      BookingMethod.INSTANT,
      BookingMethod.REQUEST,
      BookingMethod.DELIVERY,
    ],
    canEdit: true,
    showCompleteInfoCheck: true,
    completeInfoCheck: {
      [BookingMethod.INSTANT]: ['targetAudience', 'activityLevel'],
      [BookingMethod.REQUEST]: ['targetAudience'],
      [BookingMethod.DELIVERY]: ['targetAudience', 'activityLevel'],
      [BookingMethod.ADVERT]: [],
      [BookingMethod.SPACE]: ['targetAudience', 'activityLevel'],
    },
  },
  {
    id: 'requestCommunication',
    image: '/images/sections/communication.svg',
    title: 'Communication',
    emptyMessage:
      'Customers will contact you to request a quote. Please indicate what initial details you would like them to provide.',
    visibilityCheck: [BookingMethod.REQUEST],
    canEdit: true,
    showCompleteInfoCheck: true,
    completeInfoCheck: {
      [BookingMethod.INSTANT]: [],
      [BookingMethod.REQUEST]: ['bookingRequestInfo'],
      [BookingMethod.DELIVERY]: [],
      [BookingMethod.ADVERT]: [],
      [BookingMethod.SPACE]: [],
    },
  },
  {
    id: 'cancelation',
    image: '/images/sections/cancelation.svg',
    title: 'Cancelation',
    emptyMessage: 'Let guests know your cancelation policy.',
    visibilityCheck: [
      BookingMethod.INSTANT,
      BookingMethod.REQUEST,
      BookingMethod.DELIVERY,
      BookingMethod.SPACE,
    ],
    canEdit: true,
    showCompleteInfoCheck: true,
    completeInfoCheck: {
      [BookingMethod.INSTANT]: ['refundPolicy.cancellationPolicy'],
      [BookingMethod.REQUEST]: ['refundPolicy.cancellationPolicy'],
      [BookingMethod.DELIVERY]: ['refundPolicy.cancellationPolicy'],
      [BookingMethod.ADVERT]: [],
      [BookingMethod.SPACE]: ['refundPolicy.cancellationPolicy'],
    },
  },
];

export const EXPERIENCE_MANAGEMENT_MENUS: ExperienceDetailsMenuType[] = [
  {
    id: 'bookingRequests',
    image: '/images/sections/community.svg',
    title: 'Booking requests',
    visibilityCheck: [BookingMethod.REQUEST],
    canEdit: false,
    showCompleteInfoCheck: false,
    completeInfoCheck: {
      [BookingMethod.INSTANT]: [],
      [BookingMethod.REQUEST]: [],
      [BookingMethod.DELIVERY]: [],
      [BookingMethod.ADVERT]: [],
      [BookingMethod.SPACE]: [],
    },
  },
  {
    id: 'bookingSchedule',
    image: '/images/sections/schedule.svg',
    title: 'Booking schedule',
    visibilityCheck: [
      BookingMethod.INSTANT,
      BookingMethod.REQUEST,
      BookingMethod.DELIVERY,
    ],
    canEdit: false,
    showCompleteInfoCheck: false,
    completeInfoCheck: {
      [BookingMethod.INSTANT]: [],
      [BookingMethod.REQUEST]: [],
      [BookingMethod.DELIVERY]: [],
      [BookingMethod.ADVERT]: [],
      [BookingMethod.SPACE]: [],
    },
  },
];

export interface ExperienceDetailsSectionMenuType {
  id: string;
  title: string;
  visibilityCheck: BookingMethod[];
  includeApprovalSection: boolean;
  visibilityInfoCheck: { key: RecursiveKeyOf<Experience>; value: unknown }[];
  menu: ExperienceDetailsMenuType[];
}

export const EXPERIENCE_SECTIONS_MENUS: ExperienceDetailsSectionMenuType[] = [
  {
    id: 'experienceDetails',
    title: 'Experience details',
    visibilityCheck: [
      BookingMethod.INSTANT,
      BookingMethod.REQUEST,
      BookingMethod.DELIVERY,
      BookingMethod.ADVERT,
      BookingMethod.SPACE,
    ],
    visibilityInfoCheck: [],
    includeApprovalSection: true,
    menu: EXPERIENCE_DETAIL_MENUS,
  },
  {
    id: 'experienceManagement',
    title: 'Manage',
    visibilityCheck: [
      BookingMethod.REQUEST,
      BookingMethod.INSTANT,
      BookingMethod.DELIVERY,
      BookingMethod.SPACE,
    ],
    visibilityInfoCheck: [
      {
        key: 'status',
        value: [
          ExperienceStatus.Approved,
          ExperienceStatus.Hidden,
          ExperienceStatus.Published,
        ],
      },
    ],
    includeApprovalSection: false,
    menu: EXPERIENCE_MANAGEMENT_MENUS,
  },
];
